import ItemContent from "./ItemContent";
import { ReactComponent as PhoneBookIcon } from "assets/svgs/phone-book.svg";
import * as S from "./FaleComAGente.style";
import { useContext } from "react";
import OdontoContext from "context/OdontoContext";

const FaleComAGente = () => {
  const { percentageZoom } = useContext(OdontoContext);
  return (
    <>
      <S.Header zoom={percentageZoom}>
        <PhoneBookIcon />
        <S.Title variant="headline-02">Fale com a gente</S.Title>
      </S.Header>
      <S.FullDivider />
      <div id="fale-com-a-gente">
        <ItemContent />
      </div>
    </>
  );
};

export default FaleComAGente;
