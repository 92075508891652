import { Button } from "@ds/react";
import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  borderRadius: "4px",
  border: "1px solid #E3E0E2",
  padding: "20px",
  maxWidth: "100%",
  maxHeight: "172px",
  [theme.breakpoint.lg()]: {
    width: "100%",
  },
}));
export const Buttons = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: " 10px",
}));

export const PStyled = styled.p(() => ({
  fontSize: "24px",
  fontWeight: 500,
  marginTop: "0",
  paddingBottom: "12px",
  height: "52px",
  lineHeight: "36px",
}));

export const Line = styled.div(() => ({
  borderBottom: "1px solid #E3E0E2",
}));

export const ButtonStyled = styled(Button).attrs({ variant: "tertiary-01-sm" })(
  ({ theme }) => ({
    color: "#D70064",
    fontSize: "14px",
    [theme.breakpoint.lg()]: {
      fontSize: "16px",
    },
  })
);
