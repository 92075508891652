import * as S from "./Advice.style";
import "./../../styles/shared.scss";
import { useContext } from "react";
import OdontoContext from "context/OdontoContext";

const Advice = () => {
  const { percentageZoom } = useContext(OdontoContext);
  return (
    <section>
      <S.AdviceContainer zoom={percentageZoom}>
        <S.AdviceParagraph zoom={percentageZoom}>
          Este é um site exclusivo do Plano Odonto Sigma, da operadora Odonto
          Empresas Convênios Dentários Ltda, comercializado no balcão dos
          Correios.
        </S.AdviceParagraph>
      </S.AdviceContainer>
    </section>
  );
};

export default Advice;
