import React, { useState } from "react";
import CollapseItem from "../CollapseItem/CollapseItem";
import ResponsiveCard from "../ResponsiveCard";
import * as S from "./PoliticaPrivacidade.style";

function PoliticaPrivacidade() {
  const [currentItem, setCurrentItem] = useState(-1);
  const [continuar, setContinuar] = useState(false);

  const handleClick = () => {
    setContinuar(!continuar);
  };

  return (
    <S.Container id="Políticadeprivacidade">
      <S.Section>
        <ResponsiveCard
          title="Política de Privacidade"
          maxContent
          customSection={
            <>
              {/* <StatusTitle title="E-mail enviado com sucesso." /> */}
              <S.TextStyled variant="body-02-sm">
                A privacidade, a proteção de dados pessoais e o uso deles de
                forma ética, transparente, responsável, segura e adequada são
                valores essenciais para a Odonto Empresas ao tratar das suas
                informações privadas.
              </S.TextStyled>
              <S.TextStyled variant="body-02-sm">
                A gente já cuida do seu sorriso e, possivelmente, do sorriso de
                outras pessoas da sua vida, né? Agora, há mais este contínuo
                cuidado: com os seus dados pessoais.
              </S.TextStyled>
              <S.TextStyled variant="body-02-sm">
                Para isso, preparamos esta página para que você possa entender
                como cuidamos das suas informações pessoais durante todo o
                tratamento delas.
              </S.TextStyled>
              <S.TextStyled variant="body-02-sm">
                Aqui você também pode personalizar os cookies e exercer o
                direito de controlar seus dados.
              </S.TextStyled>
              <S.TextStyled variant="body-02-sm">
                Esta política demonstra o alinhamento da empresa com as
                exigências da Lei nº 13.709/2018, a Lei Geral de Proteção de
                Dados Pessoais (LGPD).
              </S.TextStyled>
              <br />
              <CollapseItem
                question="A privacidade é você!"
                answer={
                  <>
                    <br />
                    <p>
                      Seja você um cliente da Odonto Empresas ou alguém que tem
                      interesse em algum produto e/ou serviço da companhia, esta
                      política se aplica a você. Mesmo se for apenas um
                      visitante aqui dos sites –{" "}
                      <a href="https://odontoempresas.com.br/">
                        https://odontoempresas.com.br/
                      </a>{" "}
                      <a href="https://redeodontoempresas.com.br/">
                        https://redeodontoempresas.com.br/
                      </a>{" "}
                      <a href="https://www.odontoutilis.com.br/">
                        www.odontoutilis.com.br/
                      </a>{" "}
                      <a href="https://www.planoodontosigma.com.br">
                        https://www.planoodontosigma.com.br
                      </a>{" "}
                      – esta política também se aplica a você.”
                    </p>
                  </>
                }
                selectedItem={currentItem}
                onChange={(item) => setCurrentItem(item)}
                item={6}
              />
              <CollapseItem
                question="Qual é a responsabilidade da Odonto Empresas?"
                answer={
                  <>
                    <br />
                    <p>
                      A gente tem o compromisso de proteger seus dados pessoais
                      de qualquer violação que exponha sua privacidade ou cause
                      qualquer outro dano, utilizando os esforços necessários
                      para controlar os riscos dessa violação de dados.
                    </p>{" "}
                  </>
                }
                selectedItem={currentItem}
                onChange={(item) => setCurrentItem(item)}
                item={7}
              />
              <CollapseItem
                question="E se acontecer vazamento de dados?"
                answer={
                  <>
                    <br />
                    <p>
                      Nessas ocasiões, a gente será transparente com você e com
                      todos os órgãos reguladores sobre o que ocorreu, sobre
                      como a violação será corrigida e sobre quais medidas serão
                      tomadas para que o incidente não se repita.
                    </p>
                    <br />
                    <p>
                      É responsabilidade da Odonto Empresas também garantir que
                      sejam atendidos todos os requisitos legais relacionados à
                      proteção de seus dados pessoais e sensíveis, incluindo
                      informar a você como a gente utiliza seus dados e por que
                      eles são necessários, além de garantir todos os seus
                      direitos.
                    </p>
                  </>
                }
                selectedItem={currentItem}
                onChange={(item) => setCurrentItem(item)}
                item={8}
              />
              <CollapseItem
                question="O que você precisa fazer?"
                answer={
                  <>
                    <br />
                    <p>
                      O primeiro passo é ler atentamente esta Política de
                      Privacidade.
                    </p>
                    <br />
                    <p>
                      Se você já é cliente, é importante que verifique também o
                      seu contrato e entenda como a gente usa as suas
                      informações para fornecer os serviços e/ou os produtos que
                      contratou.
                    </p>
                  </>
                }
                selectedItem={currentItem}
                onChange={(item) => setCurrentItem(item)}
                item={9}
              />
              <CollapseItem
                question="Quem controla seus dados?"
                answer={
                  <>
                    <br />
                    <p>
                      A Odonto Empresas, de acordo com a Lei Geral de Proteção
                      de Dados Pessoais (LGPD), assume o papel de controlador
                      dos seus dados pessoais quando se relaciona diretamente
                      com você e coleta suas informações, como quando você
                      contrata um produto. Dessa forma, a empresa define quais
                      as finalidades de tratamento e como esses dados serão
                      utilizados, conforme as necessidades de uso, sempre
                      respeitando as bases legais vigentes e seus direitos.
                    </p>
                    <br />
                    <p>
                      A depender da relação comercial com parceiros, a gente
                      pode assumir o papel de operador de dados pessoais. Ou
                      seja, tratando os seus dados baseados exclusivamente nas
                      finalidades definidas nos contratos com esses parceiros,
                      sendo de responsabilidade deles definir as finalidades de
                      tratamento de dados e as bases legais em que esse
                      tratamento se enquadra.
                    </p>
                  </>
                }
                selectedItem={currentItem}
                onChange={(item) => setCurrentItem(item)}
                item={10}
              />
              <CollapseItem
                question="Como a gente recebe seus dados?"
                answer={
                  <>
                    <br />
                    <p>São três as fontes para receber suas informações.</p>
                    <br />
                    <ul>
                      <S.ListStyle>Dados fornecidos</S.ListStyle>
                      <p>
                        Quando você fornece dados pessoais, preenchendo um
                        formulário de cotação, por exemplo. Pode ser necessário
                        que informe seus dados pessoais quando você preenche
                        algum formulário, realiza a simulação de algum produto
                        ou acessa ofertas ou serviços. Veja abaixo quais os
                        dados pessoais solicitados.
                      </p>
                      <br />
                      <b>Para identificar você</b>
                      <br />
                      <p>
                        Nome completo, data de nascimento, sexo, CPF, RG,
                        Carteira de Habilitação ou outro registro de
                        identificação pessoal, naturalidade e nacionalidade,
                        estado civil, profissão e ocupação, se é pessoa
                        politicamente exposta (PEP), filiação, entre outros
                        dados.
                      </p>
                      <br />
                      <b>Para ter outras formas de falar com você</b>
                      <br />
                      <p>
                        Seu endereço comercial e/ou residencial, e-mail e
                        números de telefone (celular, comercial e residencial).
                      </p>
                      <br />
                      <b>
                        Para disponibilizar a você produtos e serviços ou itens
                        relacionados
                      </b>
                      <br />
                      <p>
                        A gente pede informações para atendimento a leis e
                        outros regulamentos, caso sejam aplicáveis aos produtos
                        que você comprou com a gente.
                      </p>
                      <br />
                      <p>
                        Informações bancárias também podem ser necessárias, para
                        que você receba pagamentos dos produtos que tenha
                        comprado ou para que a gente pague algum valor a que
                        você tenha direito, como devoluções e afins.
                      </p>
                      <br />
                      <b>Para identificar terceiros</b>
                      <br />
                      <p>
                        Dados cadastrais de parceiros, colaboradores, ou
                        dependentes de produtos e serviços.
                      </p>
                      <br />
                      <b>Para a segurança de todos</b>
                      <br />
                      <p>
                        Informações de usuário e senha para ter acesso à Área do
                        Cliente no site.
                      </p>
                      <br />
                      <S.ListStyle>Dados coletados</S.ListStyle>
                      <p>
                        São dados importantes para melhorar sua experiência
                        durante seu relacionamento com a gente.
                      </p>
                      <br />
                      <p>
                        Seus dados são coletados quando você liga para a gente,
                        quando compra algum produto em qualquer canal de venda
                        ou em qualquer outro momento do relacionamento com a
                        Odonto Empresas. Coletas de dados incluem:
                      </p>
                      <br />
                      <S.PaddingBox>
                        <li>
                          informações relacionadas ao histórico de seu
                          relacionamento comercial com a gente;
                        </li>
                        <li>
                          pedidos de produtos e serviços (se aplicáveis),
                          faturamento e pagamento;
                        </li>
                        <li>
                          participação em ofertas promocionais, concursos ou
                          eventos;
                        </li>
                        <li>
                          solicitações ou imprevistos que relate, por exemplo,
                          com relação à entrega de produtos ou ao desempenho de
                          serviços;
                        </li>
                        <li>
                          informações sobre compras de produtos e serviços que
                          você realize com parceiros da Odonto Empresas.
                        </li>
                      </S.PaddingBox>
                      <br />
                      <p>
                        Quando você acessa o site, a gente observa suas
                        preferências de compras, seus interesses de serviços e
                        seus dados. Essas informações são armazenadas no seu
                        computador para que, quando você volte, sua navegação
                        fique mais prática e fácil. Isso é feito através dos
                        cookies. Para saber mais, conheça a Política de Cookies
                        da Odonto Empresas.
                      </p>
                      <br />
                      <S.ListStyle>Dados assumidos</S.ListStyle>
                      <br />
                      <p>
                        Com base nos dados que você fornece e nas informações
                        coletadas durante sua jornada, a gente pode gerar novas
                        informações, de acordo com seu comportamento e seu
                        relacionamento.
                      </p>
                      <br />
                      <p>
                        Essas informações fornecidas e/ou coletadas poderão ser
                        usadas, por exemplo, para realizar estudos internos,
                        visando verificar potenciais oportunidades comerciais
                        que possam atender às suas expectativas e a de demais
                        clientes.
                      </p>
                      <br />
                      <p>
                        A gente também coleta dados pessoais públicos que, em
                        conjunto com as suas informações e a de seus contratos,
                        serão usados para as finalidades relacionadas ao
                        desempenho das atividades da Odonto Empresas, como:
                      </p>
                      <br />
                      <S.PaddingBox>
                        <li>identificar o potencial de compra de clientes</li>
                        <li>precificar produtos e serviços da empresa;</li>
                        <li>
                          identificar ilícitos que tenham algum risco para a
                          companhia ou para você.
                        </li>
                      </S.PaddingBox>
                      <br />
                      <p>
                        A gente faz esse tratamento em ambientes com dados
                        anônimos que não identificam você e, quando o uso dos
                        dados se faz necessário, em atendimento ao interesse
                        legítimo da Odonto Empresas, é realizado com a devida
                        segurança e com controle de acesso rígido e monitorado.
                      </p>
                    </ul>
                  </>
                }
                selectedItem={currentItem}
                onChange={(item) => setCurrentItem(item)}
                item={11}
              />
              {continuar ? (
                <>
                  <CollapseItem
                    question="Direito de crianças, adolescentes e pessoas incapazes"
                    answer={
                      <>
                        <br />
                        <p>
                          A gente não comercializa produtos e serviços
                          diretamente para crianças e/ou adolescentes e/ou
                          pessoas consideradas incapazes, nos termos da lei. Tal
                          ação somente é possível por meio de um representante
                          legal.
                        </p>
                      </>
                    }
                    selectedItem={currentItem}
                    onChange={(item) => setCurrentItem(item)}
                    item={12}
                  />
                  <CollapseItem
                    question="Dados sensíveis"
                    answer={
                      <>
                        <br />
                        <p>
                          Os dados considerados sensíveis são: origem racial ou
                          étnica, opiniões políticas, crenças religiosas ou
                          filosóficas, associação a um sindicato, dados
                          relacionados a saúde ou orientação sexual, além de
                          dados biométricos ou genéticos.
                        </p>
                        <p>
                          Caso seja necessário, esses dados serão solicitados
                          sempre com a justificativa legal de fornecimento dos
                          serviços contratados ou para atendimento a requisitos
                          legais e regulatórios.{" "}
                        </p>
                        <p>
                          A Odonto Empresas pode solicitar ou coletar dados
                          sensíveis durante o relacionamento com você com a
                          finalidade de execução de alguns processos
                          fundamentais para o fornecimento de produtos ou
                          serviços, listados a seguir.{" "}
                        </p>
                        <br />
                        <li>
                          <b>
                            Venda, subscrição, regulação e aceite de planos
                            odontológicos:
                          </b>
                          para aferir o risco relacionado à aquisição de planos
                          para saúde bucal, seguindo as regras legais e
                          regulatórias, podem ser solicitados a você que nos
                          informe alguns dados sensíveis, tais como declaração
                          de doenças pré-existentes, histórico de saúde, peso,
                          altura ou qualquer outra informação que ajude a gente
                          a decidir sobre os riscos relacionados para segurar
                          sua saúde odontológica. Consultas a essas informações
                          podem ser feitas também por empresas parceiras
                          especializadas na coleta e no fornecimento de
                          informações para esse fim, os chamados “birôs de
                          informação”.{" "}
                        </li>
                        <br />
                        <p>
                          Para sua tranquilidade, as informações utilizadas por
                          essas empresas são coletadas legalmente, sem infração
                          aos seus direitos, durante esses processos. As
                          questões relacionadas à proteção de seus dados também
                          estão amparadas pelos contratos firmados com essas
                          empresas, com cláusulas específicas para garantir os
                          seus direitos e a sua segurança.
                        </p>
                        <br />
                        <li>
                          <b>Prevenção a ilícitos:</b> para a gente saber que
                          você é mesmo você durante o seu acesso em sites,
                          aplicativos e centrais de relacionamento da empresa,
                          podem ser utilizados alguns dados sensíveis, como
                          geolocalização, endereço lógico do computador (IP),
                          informações sobre data e horário de uso de serviços e
                          produtos da Odonto Empresas e outros dados pessoais
                          que ajudem a gente a proteger você, assim como a
                          empresa, de impactos causados por fraudadores e
                          pessoas mal-intencionadas;{" "}
                        </li>
                        <br />
                        <li>
                          <b>Atendimento ao cliente e Ouvidoria:</b> quando você
                          entra em contato com centrais de relacionamento da
                          companhia, com o Serviço de Atendimento ao Consumidor
                          (SAC) ou com a Ouvidoria, suas ligações são gravadas
                          para sua própria proteção e para garantir que suas
                          solicitações sejam registradas corretamente. Ainda no
                          atendimento, podem ser necessários dados sensíveis
                          sobre o seu procedimento,, sobre sua vida pessoal ou
                          qualquer outra informação importante para que a gente
                          entenda melhor como ajudar, incluindo solicitações de
                          alteração cadastral, endosso, mudanças nos serviços e
                          nos produtos contratados. Todas essas informações são
                          armazenadas com toda a segurança necessária e, depois
                          de resolvido o seu problema, elas são guardadas
                          durante o período do seu contrato com a gente e,
                          depois disso, pelo período previsto em lei ou em norma
                          dos órgãos reguladores;
                        </li>
                        <br />
                        <li>
                          <b>Recrutamento e seleção de colaboradores:</b> são
                          armazenadas também informações sobre pessoas que se
                          candidatam a credenciamento ou a vagas de emprego na
                          empresa. Nesse processo de recrutamento e seleção, a
                          gente recebe informações sobre sua vida profissional,
                          sobre seu comportamento, seus hábitos e qualquer outra
                          informação que seja relevante para o processo de
                          seleção, sempre respeitando sua intimidade e sua
                          privacidade. No caso em que a pessoa não é ou
                          contratada, conservamos essas informações na base por
                          mais um ano, com o objetivo de verificar se, em novas
                          oportunidades de vaga, ela se encaixe e possa ser
                          chamada novamente para processo de seleção. Ainda
                          podem ser utilizadas plataformas de terceiros para
                          coleta de currículos e condução das etapas do
                          processo. Essas plataformas podem, com o consentimento
                          da pessoa candidata, utilizar as informações inseridas
                          para participação em processos seletivos em outras
                          empresas, sem nenhuma ligação com a Odonto Empresas.
                          Nesses casos, as plataformas de recrutamento passam a
                          controlar os dados, assim como o tratamento de dados
                          fica sob exclusiva responsabilidade desses parceiros;
                        </li>
                        <br />
                        <li>
                          <b>Recursos humanos:</b> quando você é uma pessoa
                          empregada [DG16] [LP17] ou estagiária da Odonto
                          Empresas, a gente pode coletar uma série de
                          informações sensíveis durante o curso do seu contrato,
                          dentre as quais, mas não se limitando a: dados de
                          remuneração, dados sobre sua saúde, atestados médicos,
                          informações para fornecer benefícios etc. Todos os
                          dados coletados e utilizados pelas equipes de Recursos
                          Humanos (RH) e pelo serviço médico interno têm o
                          objetivo único de cuidar das pessoas colaboradoras e
                          respeitar seu contrato de trabalho com a gente. Nenhum
                          dado de pessoa colaboradora é utilizado para nenhum
                          outro fim, seja da Odonto Empresas ou das empresas
                          parceiras que participam de processos de RH, como os
                          fornecedores de benefícios. Caso esses dados precisem
                          ser utilizados para qualquer outro fim, como ofertas
                          de produtos da empresa ou de parceiros, esse uso só
                          será feito mediante consentimento expresso. Se você
                          tiver mais dúvidas, consulte a área de RH.
                        </li>
                      </>
                    }
                    selectedItem={currentItem}
                    onChange={(item) => setCurrentItem(item)}
                    item={13}
                  />
                  <CollapseItem
                    question="Para quê a gente utiliza seus dados?"
                    answer={
                      <>
                        <br />

                        <b>
                          Em processos para disponibilizar produtos e serviços{" "}
                        </b>
                        <li>
                          Execução de contrato de produtos e/ou serviços da
                          Odonto Empresas ou itens relacionados a esse contrato,
                          como a cotação e aceite de seu plano odontológico,
                          regulação de procedimentos, pagamento de indenizações
                          etc;{" "}
                        </li>

                        <li>
                          Pedidos de produtos ou serviços e execução desses
                          pedidos;{" "}
                        </li>

                        <li>Faturamento, cobrança de pagamentos;</li>

                        <li>
                          Relacionamento com o cliente por correio, telefone,
                          e-mail ou mensagem instantânea, como bate-papo, para
                          qualquer pergunta, solicitação ou reclamação;{" "}
                        </li>
                        <li>Pesquisas de satisfação; </li>

                        <li>
                          Enviar informações sobre a manutenção ou a evolução de
                          serviços e produtos;{" "}
                        </li>
                        <li>
                          Gerenciamento das suas escolhas e direitos quanto ao
                          tratamento de seus dados pessoais.{" "}
                        </li>
                        <br />
                        <b>
                          Em campanhas de marketing e prospecção comercial
                          relacionadas a produtos e serviços{" "}
                        </b>

                        <p>
                          Eventualmente, a gente vai precisar enviar informações
                          sobre a empresa, publicações e atividades, entendendo
                          que essas informações atendem às suas expectativas.{" "}
                        </p>

                        <p>
                          Enviar também ofertas de marketing, publicidade e
                          promoções relacionadas a produtos e serviços por
                          correio, e-mail, SMS, por meio de banners em sites ou
                          nas redes sociais, sempre com a análise prévia de que
                          você tem expectativa de receber essas comunicações ou
                          a partir de seu consentimento.{" "}
                        </p>

                        <p>
                          Seus dados pessoais também podem ser usados para
                          desenvolvimento de estatísticas comerciais, para
                          melhorar os produtos, serviços e canais de venda que a
                          gente oferece.{" "}
                        </p>

                        <p>
                          Todas as vezes em que a gente enviar uma comunicação
                          para você, sempre haverá disponível a opção de
                          cancelamento do envio dessas informações e a exclusão
                          delas de nossos sistemas.{" "}
                        </p>
                        <br />
                        <b>Parceiros que têm acesso aos seus dados</b>

                        <p>
                          Alguns dos processos da Odonto Empresas exigem a
                          contratação de outras empresas para ajudar a gente a
                          executá-los.{" "}
                        </p>

                        <p>
                          Para fornecer a você determinados serviços, seus dados
                          pessoais podem ser compartilhados com parceiros de
                          negócios, que realizam o tratamento dessas informações
                          de acordo com as nossas definições.
                        </p>

                        <p>
                          Apesar desse acesso, os parceiros não podem usar os
                          seus dados para outros fins, somente para os que a
                          gente define. Essas definições estão dispostas em
                          contratos, com responsabilidades e penalidades
                          estabelecidas, para garantir que nenhum direito seu
                          seja violado.{" "}
                        </p>

                        <p>
                          Quando a relação comercial com o parceiro o coloca no
                          papel legal de controlador de dados pessoais, conforme
                          a LGPD, esses parceiros têm autonomia para definir as
                          finalidades de tratamento de seus dados pessoais.
                          Nesses casos, contratos com essas empresas garantem
                          que nenhum de seus direitos será infringido, impondo
                          obrigações e responsabilidades mútuas para a segurança
                          e a proteção de suas informações.{" "}
                        </p>

                        <p>
                          Para garantir que seus dados estejam protegidos,
                          parceiros e fornecedores críticos da Odonto Empesas
                          passam por processo de gestão rígido, que contempla
                          uma avaliação e um acompanhamento periódicos, para
                          garantir que estejam seguindo todas as nossas
                          políticas e as melhores práticas de segurança e
                          governança. A gente garante também, por meio de
                          contrato, que esses parceiros e fornecedores sejam
                          responsáveis por qualquer violação de dados pessoais
                          que tenham origem em sua estrutura.{" "}
                        </p>

                        <p>
                          Para que você conheça mais sobre as categorias de
                          parceiros e fornecedores da companhia, as dividimos
                          em: mão de obra especializada, infraestrutura e
                          tecnologia, além de parceiros comerciais.{" "}
                        </p>
                        <br />
                        <b>Fornecedores de mão de obra especializada </b>

                        <p>
                          Empresas que disponibilizam profissionais
                          especializados para executar os mais diversos
                          processos dentro da Odonto Empresas.{" "}
                        </p>

                        <p>
                          Esses profissionais, assim como o corpo de
                          funcionários e credenciados da Odonto Empresas,
                          trabalham para entregar os serviços e os produtos a
                          você, cuidam para atender às demandas regulatórias,
                          ajudam na elaboração de peças para defesa em processos
                          administrativos ou judiciais, além de acessarem suas
                          informações para ofertar produtos e serviços.{" "}
                        </p>
                        <br />
                        <b>Fornecedores de infraestrutura e tecnologia </b>

                        <p>
                          Os fornecedores são empresas que ajudam a manter o
                          ambiente operacional funcionando 24h, todos os dias,
                          para atender às suas necessidades.{" "}
                        </p>

                        <p>
                          Estão neste grupo de tecnologia: empresas que realizam
                          a manutenção e o fornecimento de servidores, sistemas,
                          equipamentos de rede e armazenamento de dados em
                          nuvem, incluídas aquelas que auxiliam a gente no
                          monitoramento dos escritórios, principalmente no que
                          diz respeito à segurança desses ambientes.{" "}
                        </p>

                        <p>
                          A Odonto Empresas, para tratamento e segurança dos
                          seus dados pessoais, formalizou acordo com a CNP
                          Seguros Holding Brasil para utilização da estrutura de
                          sistemas e tecnologias desta última, respeitando
                          sempre as bases legais aplicáveis ao cumprimento do
                          seu contrato e ao atendimento das disposições legais e
                          regulatórias vigentes.{" "}
                        </p>
                        <br />
                        <b>Parceiros comerciais </b>

                        <p>
                          Encaixam-se nessa categoria as empresas com as quais a
                          gente tem algum acordo operacional, para que
                          participem da comercialização ou de parte do
                          fornecimento de algum serviço ou produto que você
                          contratou.{" "}
                        </p>

                        <p>
                          No contexto da LGPD, para essa categoria, nem sempre o
                          parceiro de negócio assume o papel de operador de
                          dados pessoais, podendo, a depender da relação
                          comercial e contratual com a gente e com o titular dos
                          dados, ser um controlador. Nesse caso, a Odonto
                          Empresas pode assumir tanto o papel de operador como o
                          de controlador, com as responsabilidades de cada
                          organização, quanto à proteção de seus dados pessoais,
                          sendo definidas e delimitadas em contrato.
                        </p>
                      </>
                    }
                    selectedItem={currentItem}
                    onChange={(item) => setCurrentItem(item)}
                    item={14}
                  />
                  <CollapseItem
                    question="Onde seus dados são armazenados?"
                    answer={
                      <>
                        <br />
                        <p>
                          Quando a gente recebe ou coleta suas informações, elas
                          ficam armazenadas em sistemas e servidores que contam
                          com políticas de segurança necessárias para impedir
                          qualquer violação ou vazamento. Esses cuidados não
                          extinguem a chance de um problema ocorrer, porém, a
                          intenção da Odonto Empresas é a de reduzir riscos.
                        </p>

                        <p>
                          Dependendo de cada empresa, são utilizadas diversas
                          tecnologias para armazenar seus dados, listadas a
                          seguir:
                        </p>

                        <li>
                          Servidores alocados em parceiros, com administração
                          exclusiva da Odonto Empresas;{" "}
                        </li>

                        <li>
                          Servidores em nuvem, administrados pela gente, mas com
                          a infraestrutura mantida por parceiros que possuem as
                          mesmas garantias de segurança. Esses servidores podem
                          estar em outros países. Nesses casos, garantimos
                          contratualmente que os parceiros mantêm os dados
                          exclusivamente em países com a mesma rigidez de
                          controle de proteção de dados exigido pela legislação
                          brasileira;
                        </li>

                        <li>
                          Servidores de parceiros, administrados por esses
                          parceiros e monitorados pela gente e com controles de
                          segurança e proteção de dados garantidos por
                          contratos.
                        </li>
                      </>
                    }
                    selectedItem={currentItem}
                    onChange={(item) => setCurrentItem(item)}
                    item={15}
                  />
                  <CollapseItem
                    question="Por quanto tempo a gente armazena seus dados?"
                    answer={
                      <>
                        <br />
                        <p>
                          Os dados que você fornece ou que são coletados ficam
                          guardados durante o relacionamento comercial para
                          atender a reguladores e às legislações aplicáveis a
                          cada produto oferecido pela empresa. A definição do
                          período de armazenamento de seus dados para esse tipo
                          de finalidade é dada por cada regulador, a saber:
                          <br />
                          <p>
                            A Agência Nacional de Saúde Suplementar (ANS),
                            responsável pela fiscalização das operadoras de
                            planos de saúde e pela regulação do mercado, tanto
                            nos aspectos assistenciais como naqueles ligados à
                            atividade econômica, determina a guarda pelo período
                            mínimo de 20 (vinte) anos de guarda de prontuários
                            médicos e de 5 (cinco) anos para recibos de
                            pagamento.
                          </p>
                          <p>
                            A gente armazena também, durante o período em que
                            seu contrato é válido e para defesa em processos
                            judiciais e administrativos, por até dez anos.
                          </p>
                          <p>
                            Quando houver um legítimo interesse da Odonto
                            Empresas, a companhia guardará seus dados pelo
                            período necessário, seja para ofertar algum produto
                            que se entenda ser de seu interesse, seja para
                            processos que envolvam a sua segurança e prevenção a
                            fraudes.
                          </p>
                        </p>
                      </>
                    }
                    selectedItem={currentItem}
                    onChange={(item) => setCurrentItem(item)}
                    item={16}
                  />
                  <CollapseItem
                    question="Como seus dados são protegidos?"
                    answer={
                      <>
                        <br />
                        <p>
                          A gente tem uma Política de Segurança de Informação
                          que prevê todos os cuidados para que suas informações
                          estejam seguras, pelo período que seja necessário
                          mantê-las.{" "}
                        </p>

                        <p>
                          A gente trabalha para proteger os seus dados contra
                          destruição ou alteração acidental ou ilegal, perda
                          acidental, divulgação ou acesso não autorizado e
                          contra outras formas ilegais de tratamento de dados
                          pessoais e sensíveis.
                        </p>
                        <p>Além disso, a gente se preocupa em: </p>
                        <li>
                          coletar e tratar apenas dados pessoais adequados,
                          relevantes e não excessivos, conforme necessário para
                          atender aos objetivos e às finalidades definidas ou
                          que estejam dentro de suas expectativas;{" "}
                        </li>

                        <li>
                          garantir que seus dados pessoais permaneçam íntegros.
                          Para isso, a gente pode solicitar que você confirme os
                          seus dados pessoais armazenados. E, sempre que houver
                          alguma mudança no seu contexto pessoal, incentiva-se
                          que você comunique a gente para a devida atualização
                          dos seus dados.
                        </li>
                      </>
                    }
                    selectedItem={currentItem}
                    onChange={(item) => setCurrentItem(item)}
                    item={17}
                  />{" "}
                </>
              ) : null}
            </>
          }
          actions={
            !continuar
              ? [
                  {
                    label: "Continuar lendo",
                    onClick: () => {
                      handleClick();
                    },
                  },
                ]
              : null
          }
        />
      </S.Section>
    </S.Container>
  );
}

export default PoliticaPrivacidade;
