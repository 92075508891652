import styled from "styled-components/macro";
import { Text } from "@ds/react";
import { ReactComponent as CloseIcon } from "../../assets/svgs/close-icon.svg";

export const Backdrop = styled.div(({ theme, isOpen }) => ({
  display: isOpen ? "block" : "none",
  backgroundColor: "rgba(40, 46, 46, 0.8)",
  zIndex: isOpen ? 100 : 0,
  height: "103vh",
  width: "103vw",
  position: "fixed",
  right: 0,
  bottom: 0,
}));

export const SideMenuContainer = styled.nav(({ theme, isOpen }) => ({
  maxWidth: "89%",
  width: "336px",
  height: "100%",
  zIndex: isOpen ? 200 : -1,
  background: "#FFF",
  position: "fixed",
  left: 0,
  bottom: 0,
  transition: "transform 0.3s ease-out, z-index 0.2s ease-out",
  transform: isOpen ? "translateX(0)" : "translateX(-100%)",

  p: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.color.brandPrimary.light,
    padding: 20,
    margin: "0px",
  },
}));

export const MenuText = styled(Text)(({ theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: 14,
  color: theme.color.neutral["08"],
}));

export const MenuList = styled.div(({ theme }) => ({
  padding: "0 16px",
  display: "flex",
  flexDirection: "column",
  overflowY: "clip",
}));

export const MenuItem = styled(Text)(({ theme }) => ({
  padding: "25px 0",
  borderBottom: "1px solid #E5E5E5",
  cursor: "pointer",

  span: {
    color: theme.color.brandPrimary.light,
    fontFamily: "Raleway, sans-serif",
  },

  div: {
    display: "flex",
    alignItems: "center",
  },
}));

export const MenuItemIcon = styled(Text)(({ theme }) => ({
  marginBottom: "2px",
  marginRight: "10px",
}));

export const Close = styled(CloseIcon)(({ theme }) => ({
  cursor: "pointer",
}));
