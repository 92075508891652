import React from "react";
import { ReactComponent as LogoOdonto } from "../../assets/svgs/Logo_Odonto.svg";
import { ReactComponent as LogoCNP } from "../../assets/svgs/Logo_CNP.svg";
import * as S from "./FooterPortalPrivacidade.style";

function FooterPortalPrivacidade() {
  return (
    <S.Footer>
      <a href="#top">
        <LogoOdonto />
      </a>
      <a href="#top">
        <LogoCNP />
      </a>
    </S.Footer>
  );
}

export default FooterPortalPrivacidade;
