import styled from "styled-components";

export const Box = styled.div(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "32px",
}));

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 20px 20px rgba(45, 41, 38, 0.08)",
  background: theme.color.neutral["08"],
  marginBottom: "16px",

  [theme.breakpoint.lg()]: {},
}));

export const CardsContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  [theme.breakpoint.lg()]: {
    flexDirection: "row",
  },
}));
