import BurgerButton from "components/BurgerButton";
import React from "react";
import MenuList from "../MenuList";
import * as S from "./SideMenu.style";

const SideMenu = ({ open, handleOpen }) => {
  return (
    <>
      <S.Backdrop isOpen={open} />
      <S.SideMenuContainer isOpen={open}>
        <p>
          <S.MenuText>Menu</S.MenuText>
          <BurgerButton setOpen={handleOpen} open={open} />
        </p>

        <MenuList setOpen={handleOpen} open={open} />
      </S.SideMenuContainer>
    </>
  );
};

export default SideMenu;
