import { MAX_CONTENT_WIDTH, MAX_WIDTH } from "config/resolution";
import styled from "styled-components/macro";

export const Content = styled.div(({ theme, noPadding, fullWidth, zoom }) => ({
  padding: !noPadding && "0 16px",
  margin: "auto",

  [theme.breakpoint.lg()]: {
    padding: zoom < 140 && !noPadding && "0 36px",
  },

  [theme.breakpoint.xl()]: {
    maxWidth: zoom < 120 || !fullWidth ? MAX_WIDTH : MAX_CONTENT_WIDTH,
    padding: zoom < 120 && !noPadding && "0 112px",
  },
}));
