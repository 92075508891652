import { createContext, useState } from "react";

const OdontoContext = createContext();

export const OdontoProvider = ({ children }) => {
  const [percentageZoom, setPercentageZoom] = useState(100);

  return (
    <OdontoContext.Provider
      value={{
        percentageZoom,
        setPercentageZoom,
      }}
    >
      {children}
    </OdontoContext.Provider>
  );
};

export default OdontoContext;
