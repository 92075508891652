import * as S from "./BurgerButton.style";

const BurgerButton = ({ open, setOpen, zoom }) => {
  return (
    <S.StyledBurgerButton
      open={open}
      zoom={zoom}
      onClick={() => setOpen(!open)}
    >
      <div />
      <div />
      <div />
    </S.StyledBurgerButton>
  );
};

export default BurgerButton;
