import styled from "styled-components/macro";

export const Footer = styled.footer(({ theme: { color } }) => ({
  backgroundColor: "#001845",
  zIndex: "1",
}));

export const FooterContentWrapper = styled.div(({ theme: { color } }) => ({
  display: "block",
  flexDirection: "column",
  padding: 0,
  left: 0,
  bottom: 0,
  width: "100%",
  color: color.neutral["08"],
}));

export const FooterContent = styled.div(
  ({ theme: { spacing, breakpoint }, zoom }) => ({
    display: "flex",
    flexDirection: "column",
    padding: `0 16px ${spacing.stack.xl}`,
    background: "transparent",

    [breakpoint.lg()]: {
      margin: zoom < 130 && 0,
      padding: zoom < 130 && `0 36px ${spacing.stack.md}`,
    },

    [breakpoint.xl()]: {
      maxWidth: zoom < 120 && "1440px",
      margin: zoom < 120 && "auto",
      padding: zoom < 120 && "0 112px",
    },
  })
);
