import * as S from "./CustomTable.style";

const CustomTable = ({ headers, rows }) => {
  return (
    <S.Table>
      <S.Row>
        {headers.map((header) => (
          <S.Column key={header}>{header}</S.Column>
        ))}
      </S.Row>

      {rows.map((row, i) => (
        <S.Row key={i}>
          {row.map((data, id) => (
            <S.Column key={id}>{data}</S.Column>
          ))}
        </S.Row>
      ))}
    </S.Table>
  );
};

export default CustomTable;
