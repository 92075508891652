import { ThemeProvider as DSThemeProvider } from "@ds/react";
import { cnpSeguradora } from "@ds/tema-cnp";
import { OdontoProvider } from "context/OdontoContext";
import Home from "pages/Home.js";
import PortalPrivacidade from "pages/PortalPrivacidade";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

function App() {
  return (
    <>
      <DSThemeProvider theme={cnpSeguradora}>
        <OdontoProvider>
          <BrowserRouter>
            <Routes>
              <Route index element={<Home />} />
              <Route
                path="/portalprivacidade"
                element={<PortalPrivacidade />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </OdontoProvider>
      </DSThemeProvider>
    </>
  );
}

export default App;
