import styled from "styled-components";


export const CorreiosSection = styled.div(({ theme }) => ({
    backgroundColor: "#002364",
}))
 
export const CorreiosContent = styled.div(({ theme, zoom }) => ({
    borderTop: '1px solid #d1dbe3',
    padding: '40px 16px',
    display: 'flex',
    flexDirection: 'column',

    
    [theme.breakpoint.lg()]: {
        padding: '65px 112px',
        fontSize: '28px',

        flexDirection:  zoom < "150" ? 'row' : 'column' ,
        justifyContent: 'space-between',

        maxWidth: '1440px',
        margin: 'auto',
    },
}))

export const ParagraphProcureAgencia = styled.p(({ theme }) => ({
    lineHeight: '150%',
    width: '100%',
    marginTop: '20px',
    marginBottom: '32px',
    fontSize: '20px',
    color: '#ffff',

    [theme.breakpoint.lg()]: {
        fontSize: '24px',
        width: '580px'
    }
}))

export const CorreiosButton = styled.button(({ theme }) => ({
    display: 'inline-block',
    position: 'relative',
    outline: 'none',
    textAlign: 'center',
    textDecoration: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
    pointerEvents: 'auto',
    borderRadius: '10px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight:'14px',
    background: 'rgb(215, 0, 100) 76.43%',
    color: '#fff',
    padding: '8px 24px',
    border: 'none',
    width: '100%',
    height: '48px',

    [theme.breakpoint.lg()]: {
        fontSize: '16px',
        width: '280px',
        height: '52px',
    } 
}))