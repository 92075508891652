import { Button } from "@ds/react";
import styled from "styled-components";

export const HeaderContent = styled.div(({ theme, zoom }) => ({
  padding: "0 16px",
  ".odontoMobile": {
    maxWidth: "10vw",
  },

  [theme.breakpoint.lg()]: {
    padding: zoom < "120" ? "0 112px" : "0 16px",
  },
}));

export const LogoContainer = styled.div(({ theme, zoom }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "18px",
  alignItems: "center",

  [theme.breakpoint.md()]: {
    maxWidth: "80vw",
  },

  [theme.breakpoint.lg()]: {
    gap: zoom < "120" ? "20px" : "18px",
  },
}));

export const MenuList = styled.div(({ theme, zoom }) => ({
  cursor: "pointer",

  [theme.breakpoint.lg()]: {
    display: zoom < "120" ? "none" : "block",
  },
}));

export const Item = styled.div(({ theme, zoom }) => ({
  display: 'flex',
  alignItems: 'baseline',
  gap: "40px",
  ".zoom, .zoomIn": {
    cursor: "pointer",
  },
  [theme.breakpoint.lg()]: {
    display: zoom < "120" ? "flex" : "block",
    alignSelf: zoom < "120" ? "center" : "auto",
    gap: zoom < "120" ? "40px" : "0",
  },
}));

export const ContainerItens = styled.div(({ theme, zoom }) => ({
  [theme.breakpoint.lg()]: {
    display: zoom < "120" ? "flex" : "block",
    justifyContent: "space-between",
    width: zoom < "120" ? "100vw" : "auto",
  },
}));

export const MobileHeaderContainer = styled.div(({ theme, zoom }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",

  [theme.breakpoint.xl()]: {
    display: zoom < "120" ? "none" : "flex",
  },
}));

export const Flex = styled.section(({ theme, zoom }) => ({
  display: "flex",
  alignItems: "center",

  svg: {
    marginRight: "18px",
  },

  [theme.breakpoint.xl()]: {
    display: zoom < "120" ? "none" : "flex",
  },
}));

export const HeaderButton = styled(Button)(({ theme }) => ({
  borderRadius: "4px",
}));

export const Padding = styled.div(({ theme, zoom }) => ({
  padding: "0 32px",
  [theme.breakpoint.xl()]: {
    padding: zoom < "120" ? "0 82px" : "0 32px",
    margin: zoom < "120" ? 0 : "auto",
  },
}));

export const MobileWebIcons = styled.div`
  display: flex;
  align-items: baseline;

  gap: 28px;
`