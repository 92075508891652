import { useDeviceType } from "@ds/react";
import React, { useContext, useState } from "react";
import BurgerButton from "components/BurgerButton";
import MenuList from "components/MenuList";

import logoCorreios from "assets/svgs/Logo-Correios.svg";
import logoCNP from "assets/svgs/Logo-CNP-Seguradora-Negative.svg";
import { ReactComponent as ZoomIn } from "assets/svgs/zoom-in.svg";
import { ReactComponent as ZoomOut } from "assets/svgs/zoom-out.svg";

import * as S from "./CommonHeader.style";
import OdontoContext from "context/OdontoContext";

const CommonHeader = () => {
  const [open, setOpen] = useState(false);
  const { percentageZoom, setPercentageZoom } = useContext(OdontoContext);
  const { xl } = useDeviceType();

  const login = () => {
    window.open(
      "https://www.odontoutilis.com.br/ConnectOdontoWeb/websegurado-index-login.action",
      "_blank"
    );
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleZoom = (zoom) => {
    if (zoom === "in") {
      document.body.style.zoom = `${percentageZoom + 10}%`; // NOSONAR é a forma que a equipe de ux pediu o zoom
      setPercentageZoom(percentageZoom + 10);
    } else if (zoom === "out") {
      document.body.style.zoom = `${percentageZoom - 10}%`; // NOSONAR é a forma que a equipe de ux pediu o zoom
      setPercentageZoom(percentageZoom - 10);
    }
  };

  const onKeyUp = (e, zoom) => {
    if (e.key === "Enter") handleZoom(zoom);
  };

  const ZoomContent = (
    <>
      {xl ? (
        <>
          <ZoomIn
            tabIndex={0}
            alt="icone zoom in"
            className="zoomIn"
            onKeyUp={(e) => onKeyUp(e, "in")}
            onClick={() => handleZoom("in")}
          />
          <ZoomOut
            tabIndex={0}
            onKeyUp={(e) => onKeyUp(e, "out")}
            alt="icone zoom out"
            className="zoom"
            onClick={() => handleZoom("out")}
          />
          <S.HeaderButton
            zoom={percentageZoom}
            role="button"
            variant="primary-02-inverse-sm"
            onClick={() => login()}
          >
            Login
          </S.HeaderButton>
        </>
      ) : (
        <S.HeaderButton
          zoom={percentageZoom}
          role="button"
          variant="primary-02-inverse-sm"
          onClick={() => login()}
        >
          Login
        </S.HeaderButton>
      )}
    </>
  );

  return (
    <S.HeaderContent zoom={percentageZoom}>
      {xl && percentageZoom < 120 ? (
        <S.LogoContainer zoom={percentageZoom}>
          <S.ContainerItens className="Resolution" zoom={percentageZoom}>
            <S.Item zoom={percentageZoom}>
              <img src={logoCNP} height={40} alt="Logo CNP Capitalização" />
              <img src={logoCorreios} height={24} alt="Logo Correios" />
            </S.Item>
            <S.Item zoom={percentageZoom}>
              <MenuList />
            </S.Item>
            <S.Item zoom={percentageZoom}>{ZoomContent}</S.Item>
          </S.ContainerItens>
        </S.LogoContainer>
      ) : (
        <S.MobileHeaderContainer zoom={percentageZoom}>
          <S.Flex zoom={percentageZoom}>
            <BurgerButton
              zoom={percentageZoom}
              setOpen={handleOpen}
              open={open}
              role="button"
            />
            <S.MobileWebIcons>
              <img height={28} src={logoCNP} alt="Logo CNP Capitalização" />
              <img height={20} src={logoCorreios} alt="Logo Correios" />
            </S.MobileWebIcons>
          </S.Flex>

          <S.Flex zoom={percentageZoom}>{ZoomContent}</S.Flex>
        </S.MobileHeaderContainer>
      )}
    </S.HeaderContent>
  );
};

export default CommonHeader;
