import React, { useContext, useState } from "react";
import CollapseItem from "../CollapseItem/CollapseItem";
import "./../../styles/shared.scss";

import * as S from "./TiraDuvidas.style.js";
import OdontoContext from "context/OdontoContext";


const TiraDuvidas = () => {
  const { percentageZoom } = useContext(OdontoContext);
  const [currentItem, setCurrentItem] = useState(-1);
  return (
    <S.ContainerDoubts zoom={percentageZoom}>
      <div className="Resolution">
        <S.TakeQuestion zoom={percentageZoom} id="ajuda">
          Tire todas as suas dúvidas
        </S.TakeQuestion>
        <S.Questions zoom={percentageZoom}>Perguntas frequentes</S.Questions>
        <S.SectionCollapse zoom={percentageZoom}>
          <div className="col1">
            <CollapseItem
              question="Por que preciso de um plano odontológico?"
              answer={`Porque sua saúde começa na boca. Com um plano, você tem condições de prevenir e tratar cáries, 
            mau hálito, gengivite, inflamações e outras doenças. 
            Você ganha saúde e também um sorriso mais bonito. Partiu, plano?`}
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={23}
            />

            <CollapseItem
              question="Posso usar o plano quantas vezes quiser?"
              answer={`Sim, você não tem limite de utilização para os procedimentos cobertos conforme ROL da ANS e condições gerais do plano.`}
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={24}
            />

            <CollapseItem
              question="Como funcionam os sorteios de R$ 10 mil?"
              answer={`Você receberá, por e-mail, um número da sorte que valerá por 12 meses. Os sorteios serão realizados pela Loteria Federal e o prêmio é livre de impostos. Para concorrer, você deve estar em dia com as mensalidades. Boa sorte!`}
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={25}
            />

            <CollapseItem
              question="Tem plano para a minha família?"
              answer={`Sua família não fica de fora. Você pode incluir dependentes, mesmo que não sejam parentes. Além disso, crianças de 0 a 3 anos são isentas de cobrança e a inclusão delas como dependentes é feita apenas pela nossa central de atendimento.`}
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={26}
            />

            <CollapseItem
              question="Este plano cobre o valor das consultas totalmente ou tenho que completar?"
              answer={`Você não terá nenhum custo adicional. Pague apenas a mensalidade do plano e não se preocupe com nenhuma despesa nas consultas. Viu como vale a pena?`}
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={27}
            />
            <CollapseItem
              question="Como consulto a rede credenciada por SMS?"
              answer={
                <>
                  Envie um SMS para 29734 com a mensagem: ODONTO + CEP (local
                  onde busca o dentista credenciado). Se quiser uma busca por
                  especialista, mande a mensagem: ODONTO + CEP + SIGLA DA
                  ESPECIALIDADE. <br />
                  Confira a lista das especilidades. Digite somente a sigla.{" "}
                  <br />
                  CIR - Cirurgia Odontológica
                  <br /> END - Endodontia
                  <br /> IMP - Implantodontia
                  <br /> ODP - Odontopediatria
                  <br /> ORT - Ortodontia
                  <br /> PER - Periodontia
                  <br /> PRO - Prótese
                  <br /> RAD - Radiologia
                  <br /> URG - Urgência Consultório, Pronto Socorro 24 horas,
                  Pronto Socorro Diurno
                </>
              }
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={28}
            />
          </div>
          <div className="col2">
            <CollapseItem
              question="Concorro a R$ 10 mil já no primeiro mês do contrato?"
              answer={`Sim. Você concorre a partir do primeiro mês. E vale por 12 meses, 
            desde que você esteja em dia com as mensalidades. Então cruze os dedos!`}
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={29}
            />

            <CollapseItem
              question="O plano realiza quais serviços e procedimentos dentários?"
              answer={`Nosso plano é completo e cobre urgência e emergência, consultas, odontopediatria, radiologia (inclusive panorâmica), prevenção (limpeza e aplicação de flúor), cirurgia (extração de dentes), dentística (restaurações), endodontia (tratamento de canal), periodontia (tratamento de gengiva) e próteses (ROL ANS – confirme sua cobertura de próteses antes da utilização). `}
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={30}
            />

            <CollapseItem
              question="E se o meu dentista não aceitar o plano? Tem reembolso?"
              answer={
                <>
                  Não temos reembolso, mas o seu dentista de confiança pode
                  fazer parte da nossa rede. Basta ele se cadastrar em:{" "}
                  <a
                    href="https://redeodontoempresas.com.br/credenciamento/solicite-credenciamento "
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://redeodontoempresas.com.br/credenciamento/solicite-credenciamento
                  </a>
                  . Assim, todo mundo ganha: você, seu dentista e nossa rede
                  cresce ainda mais. Não é uma boa ideia?
                </>
              }
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={31}
            />

            <CollapseItem
              question="O Plano odonto tem carência?"
              answer={`Tem. Nossa carência é de 24 horas para urgência/emergência, 60 dias para tratamentos nas especialidades de consultas, odontopediatria, prevenção, endodontia, cirurgia, radiologia, dentística e 180 dias para procedimentos da especialidade de Prótese.`}
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={32}
            />
            <CollapseItem
              question="O aparelho ortondôntico é totalmente gratuito?"
              answer={`A confecção do aparelho é grátis. Você pagará apenas pela manutenção mensal e pela documentação ortodôntica. E esse acerto é feito direto com o dentista. Este benefício vale a partir da adesão do plano e para utilizar basta apresentar a carteirinha para um especialista de nossa rede credenciada.
            `}
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={33}
            />
          </div>
          </S.SectionCollapse>
      </div>
    </S.ContainerDoubts>
  );
};

export default TiraDuvidas;
