import { Button, FullTextDoubleButton } from "@ds/react";
import { andCompare, ternary } from "../../utils/sonar";
import React from "react";
import Spinner from "../Spinner";
import * as S from "./ResponsiveCard.style";
import useBreakpoints from "utils/useBreakpoints";

const renderActions = (actions, desktop) => {
  if (andCompare(actions.length === 1, !desktop)) {
    const action = actions[0];
    const PrimaryIcon = action.icon;
    const hasVariant = action.variant;
    const variant = hasVariant || "full-text-single-md-icon";
    return (
      <Button
        variant={variant}
        onClick={action.onClick}
        className={`action-buttons make-click ${andCompare(
          hasVariant,
          "main-button make-click"
        )}`}
        primary={action.primary}
      >
        {ternary(action.loading, <Spinner />, PrimaryIcon && <PrimaryIcon />)}
        {action.label}
      </Button>
    );
  }

  if (actions.length === 2 && !desktop) {
    const PrimaryIcon = actions[0].icon;
    const SecondaryIcon = actions[1].icon;

    return (
      <FullTextDoubleButton
        className="action-buttons"
        variant="double"
        primaryProps={{
          children: (
            <>
              {PrimaryIcon && <PrimaryIcon className="make-click" />}
              {actions[0].label}
            </>
          ),
          onClick: actions[0].onClick,
          className: "make-click",
        }}
        secondaryProps={{
          children: (
            <>
              {SecondaryIcon && <SecondaryIcon />}
              {actions[1].label}
            </>
          ),
          onClick: actions[1].onClick,
          className: "make-click",
        }}
      />
    );
  }

  return actions.map(
    ({ label, onClick, noStyle, primary, ...props }, index) => {
      const isSecondary = !primary && (actions.length === 1 || index === 1);
      return (
        <Button
          {...props}
          key={index}
          variant={ternary(isSecondary, "secondary-md", "primary-01-md")}
          onClick={onClick}
          className={[
            isSecondary && "secondary make-click",
            !isSecondary && "make-click",
            actions.length === 1 && "single",
            !!noStyle && "no-style",
          ]}
          loading={props.loading}
        >
          {label}
        </Button>
      );
    }
  );
};

const ResponsiveCard = ({
  lead,
  title,
  caption,
  description,
  customSection,
  actions,
  reverse,
  reverseActions,
  maxContent,
  noPadding,
  noInfo,
  smallTitle,
  noHorizontalPadding,
  ...props
}) => {
  const breakpoints = useBreakpoints();

  const isMobile = !breakpoints.lg;
  const isDesktop = breakpoints.lg;

  const haveContent =
    lead || title || caption || description || actions || maxContent;
  const getCaption = () =>
    React.isValidElement(caption) ? (
      caption
    ) : (
      <S.Caption variant={`caption-01`}>{caption}</S.Caption>
    );

  return (
    <S.ResponsiveCard
      className="make-section"
      reverse={reverse}
      noPadding={noPadding}
      noHorizontalPadding={noHorizontalPadding}
      data-section={title?.toLowerCase?.()}
      noInfo={noInfo}
      {...props}
    >
      <S.Item maxContent={maxContent} className="custom-content">
        {customSection}
        {actions && (
          <>
            {isMobile && (
              <S.Actions>{renderActions(actions, breakpoints.lg)}</S.Actions>
            )}
            {isDesktop && !reverseActions && (
              <S.Actions>{renderActions(actions, breakpoints.lg)}</S.Actions>
            )}
          </>
        )}
      </S.Item>
      {haveContent && (
        <S.Item className="content">
          {lead && <S.Lead variant="lead-text">{lead}</S.Lead>}
          {title && (
            <S.Title variant="headline-05" smallTitle={smallTitle}>
              {title}
            </S.Title>
          )}
          {caption && getCaption()}
          {description && (
            <S.Description variant="body-02-md">{description}</S.Description>
          )}

          {actions && (
            <>
              {isDesktop && reverseActions && (
                <S.Actions>{renderActions(actions, breakpoints.lg)}</S.Actions>
              )}
            </>
          )}
        </S.Item>
      )}
    </S.ResponsiveCard>
  );
};

export default ResponsiveCard;
