import { Text } from "@ds/react";
import styled from "styled-components";

export const Coberturas = styled.div(({ theme, zoom }) => ({
  backgroundColor: "#ffffff",
  [theme.breakpoint.lg()]: {
    padding: zoom < 120 && "60px 112px",
  },
}));

export const Specialty = styled.div(({ theme, zoom }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",

  img: {
    paddingLeft: "16px",
    display: "block",
  },

  [theme.breakpoint.lg()]: {
    paddingLeft: zoom < 120 && "0",
    marginBottom: zoom < 120 && "40px",
    img: {
      width: zoom < 120 && "384px",
      height: zoom < 120 && "236.07px",
    },
  },
}));

export const ImageContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
});

export const SorriaText = styled.div(({ theme, zoom }) => ({
  marginTop: "-23px",
  position: "relative",

  [theme.breakpoint.md()]: {
    paddingLeft: zoom < 140 && "65px",
  },

  [theme.breakpoint.lg()]: {
    padding: zoom < 120 && "0",
  },
}));

export const Container = styled.div(({ theme, zoom }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  padding: "40px 16px",

  [theme.breakpoint.lg()]: {
    padding: zoom < 120 && "0",
    marginBottom: zoom < 120 && "0px",
    border: zoom < 120 && "none",
    marginTop: zoom < 120 && "40px",
    gap: zoom < 120 && "60px",
  },
}));

export const Nomeprocedimento = styled(Text)({
  color: "#002364",
  fontSize: "24px",
  lineHeight: "36px",
  margin: "0px",
  fontWeight: "700",
});
export const RewardsList = styled.div(({ theme, zoom }) => ({
  display: "flex",
  gap: "32px",
  flexDirection: "column",

  [theme.breakpoint.lg()]: {
    flexDirection: zoom < 120 && "row",
  },
}));

export const RewardItem = styled.div(({ theme, gap, zoom }) => ({
  borderLeft: "2px solid #d70064",
  paddingLeft: "16px",
  width: "95%",
  marginBottom: gap && "25px",

  [theme.breakpoint.lg()]: {
    marginBottom: zoom < 120 && gap && "0px",
  },
}));

export const RewardItemBody = styled.p(({ theme, zoom }) => ({
  color: "#002364",
  fontSize: "18px",
  lineHeight: "28.8px",
  marginBottom: "0",

  [theme.breakpoint.md()]: {
    fontSize: zoom < 120 && "20px",
    lineHeight: zoom < 120 && "32px",
  },
}));

export const Titlespecialty = styled.p(({ theme, primaryColor, zoom }) => ({
  color: "#ffffff",
  fontWeight: "700",

  padding: "8px 16px",
  width: "fit-content",
  margin: "8px 16px",
  lineHeight: "42px",

  backgroundColor: primaryColor ? "#d70064" : "#002364",
  fontSize: primaryColor ? "28px" : "24px",

  [theme.breakpoint.lg()]: {
    margin: zoom < 120 && "8px 0",
    width: zoom < 120 && "fit-content",
    lineHeight: zoom < 120 && "48px",
    fontSize: zoom < 120 && primaryColor ? "32px" : "28px",
  },
}));

export const TitleContainer = styled.div({
  marginLeft: "-20px",
});
