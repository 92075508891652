import styled from "styled-components";

export const AdviceContainer = styled.div(({ theme, zoom }) => ({
  padding: "1px 16px",
  backgroundColor: "#000d26",

  [theme.breakpoint.lg()]: {
    padding: zoom < 120 ? "24px 112px" : "1px 16px",
  },
}));

export const AdviceParagraph = styled.div(({ theme, zoom }) => ({
  color: "#fff",
  fontSize: "12px",
  fontWeight: "700",
  lineHeight: "18px",

  [theme.breakpoint.lg()]: {
    fontSize: zoom < 120 ? "14px" : "12px",
    lineHeight: zoom < 120 ? "21px" : "18px",
  },
}));
