/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useContext } from "react";
import "./../../styles/shared.scss";

import * as S from "./RedeCredenciada.style"
import OdontoContext from "context/OdontoContext";

const RedeCredenciada = () => {
  const { percentageZoom } = useContext(OdontoContext);

  return (
    <S.RedeCredenciadaSection zoom={percentageZoom} className="make-section" id="encontre-um-dentista">
      <div>
        <S.RedeCredenciadaTitle>Encontre um dentista</S.RedeCredenciadaTitle>
      </div>
      <div>
        <S.RedeCredenciadaDescription>São mais de 8 mil profissionais, encontre um dentista mais perto de você.</S.RedeCredenciadaDescription>
        <S.RedeCredenciadaButton
          role="button"
          onClick={() =>
            window.open("https://odontoempresas.com.br/cliente/encontre-um-dentista/", "_blank")
          }
        >
          Buscar um dentista
        </S.RedeCredenciadaButton>
      </div>
    </S.RedeCredenciadaSection>
  );
};

export default RedeCredenciada;
