import * as S from "./Spinner.style";

const Spinner = (props) => {
  return (
    <S.Spinner {...props}>
      <S.Ring>
        <div />
        <div />
        <div />
        <div />
      </S.Ring>
    </S.Spinner>
  );
};

export default Spinner;
