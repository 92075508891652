import React from "react";
import OriginalBanner from "components/OriginalBanner";
import BannerBlackFriday from "components/BannerBlackFriday";

const BannerHome = () => {
  const DefaultBanner = <OriginalBanner />;
  const BlackFridayBanner = <BannerBlackFriday/>
  return Date.now() <= Date.parse("2022-11-30") ? BlackFridayBanner : DefaultBanner;
};
export default BannerHome;
