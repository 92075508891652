import styled from "styled-components/macro";

export const Table = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const Row = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 4px",

  "&:first-child": {
    backgroundColor: theme.color.line.medium,
    alignItems: "center",
  },

  "&:not(:first-child)": {
    borderBottom: `1px solid ${theme.color.line.medium}`,

    "div:first-child": {
      fontWeight: "bold",
    },
  },
}));

export const Column = styled.div(({ theme }) => ({
  padding: "0 4px",
  flexBasis: 0,
  flexGrow: 1,
}));
