import styled from "styled-components/macro";

export const Banner = styled.div(({ theme }) => ({
  position: "relative",
  "> *": {
    display: "grid",
    maxWidth: "100%",
    "> *": {
      gridArea: "1 / 1 / 1 / 1",
    },
  },
}));

export const ImageContainer = styled.div(({ theme, zoom }) => ({
  img: {
    width: "100%",
    display: "block",
    objectFit: "cover",
    height: "800px",
    objectPosition: "left",

    [theme.breakpoint.xl()]: {
      height: "100%",
      objectPosition: "top",
    },
  },
}));

export const BannerContent = styled.div(({ theme, zoom }) => ({
  padding: "442px 0 0 0",

  [theme.breakpoint.lg()]: {
    padding: "60px 0",
  },
}));
