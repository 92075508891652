import React, { useContext } from "react";
import * as S from "./Footer.style";
import { Divider } from "@ds/react";

import LinksSection from "./LinksSection";
import DetailsSection from "./DetailsSection";
import FaleComAGente from "./FaleComAGente/FaleComAGente";
import OdontoContext from "context/OdontoContext";

const Footer = () => {
  const { percentageZoom } = useContext(OdontoContext);
  return (
    <S.Footer
      data-section="footer"
      className="make-section"
      data-label="footer section"
    >
      <S.FooterContentWrapper>
        <FaleComAGente />
        <S.FooterContent zoom={percentageZoom}>
          <LinksSection />
          <Divider />
          <DetailsSection />
        </S.FooterContent>
      </S.FooterContentWrapper>
    </S.Footer>
  );
};

export default Footer;
