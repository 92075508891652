import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  padding: "28px",
  lineHeight: "24px",
  [theme.breakpoint.lg()]: {
    padding: "32px 112px",
  },
}));

export const PStyled = styled.p(() => ({
  fontSize: "16px",
  fontWeight: 500,
}));

export const LinkStyled = styled.a(() => ({
  color: "#D70064",
}));

export const Gapping = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 20px 20px rgba(45, 41, 38, 0.08)",
  background: theme.color.neutral["08"],
  marginBottom: "16px",

  [theme.breakpoint.lg()]: {
    marginBottom: "20px",
    flexDirection: "row",
  },
}));
