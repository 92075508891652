import styled from "styled-components";


export const RedeCredenciadaSection = styled.section(( { theme, zoom }) => ({
    backgroundColor: "#fff",
    padding: "46px 21px",
    
    color:"#454e4f",


    [theme.breakpoint.lg()]: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: zoom < "150" ? 'row' : 'column',
        padding: "65px 112px",

        maxWidth: '1440px',
        margin: 'auto',
    }
  

}))
export const RedeCredenciadaTitle = styled.h2(( { theme }) => ({
    fontSize: "28px",
    lineHeight: "150%",

    [theme.breakpoint.lg()]: {
        fontSize: '32px',
        marginTop: '20px',
    }
}))

export const RedeCredenciadaDescription = styled.p(( { theme }) => ({
    marginBottom: "32px",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "160%",

    [theme.breakpoint.lg()]: {
        marginBottom: '32px',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '160%',
    }
}))
export const RedeCredenciadaButton = styled.button(( { theme }) => ({
    borderRadius: '8px',
    fontWeight: 700,
    background: 'transparent',
    padding: '16px 36px',
    border: '1px solid #002364',
    fontSize: '14px',
    lineHeight: '16px',
    cursor: 'pointer',
    color: '#002364',

    [theme.breakpoint.lg()]: {
        fontSize: '16px',
        lineHeight: '19px',
    }

}))