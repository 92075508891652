import styled from "styled-components";
import { Text } from "@ds/react";

export const ResponsiveCard = styled.div(
  ({ theme, reverse, noPadding, noHorizontalPadding, noInfo }) => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.color.neutral["08"],
    padding: `${noPadding ? 0 : theme.spacing.stack.xs} ${
      theme.spacing.stack.sm
    }`,
    "> div": {
      paddingTop: noPadding && 0,
      paddingBottom: noPadding && 0,
    },

    gap: noInfo ? 0 : theme.spacing.stack.xs,

    [theme.breakpoint.lg()]: {
      flexDirection: reverse ? "row-reverse" : "row",
      gap: theme.spacing.stack.xs,
      paddingLeft: noHorizontalPadding && 0,
      paddingRight: noHorizontalPadding && 0,
      padding: `${noPadding ? 0 : theme.spacing.stack.xs} ${"112px"}`,
    },
  })
);

export const Item = styled.div(({ theme, maxContent }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  flexBasis: "50%",
  alignItems: "center",
  gap: "16px",
  order: 2,

  "&.content": {
    alignItems: "flex-start", // NOSONAR propriedade CSS.
    order: 1,
  },

  [theme.breakpoint.lg()]: {
    padding: `${theme.spacing.inline.md} 0`,
    lineHeight: "24px",
    flexBasis: maxContent && "80%",
  },
}));

export const Actions = styled.div(
  ({ theme: { breakpoint, color, spacing } }) => ({
    display: "flex",
    alignSelf: "flex-start", // NOSONAR propriedade CSS.
    justifyContent: "center",
    width: "100%",
    flexWrap: "wrap",

    button: {
      flexGrow: 1,
    },

    ".action-buttons": {
      width: "100vw",
      marginLeft: `-${spacing.stack.sm}`,
      marginRight: `-${spacing.stack.sm}`,
      transform: `translateY(${spacing.stack.xs})`,
      color: color.brandSecondary.light,

      "&.main-button": {
        margin: 0,
        transform: "none",
        color: color.neutral["08"],
      },

      button: {
        color: color.brandSecondary.light,

        svg: {
          marginRight: "8px",
        },
      },
    },

    [breakpoint.lg()]: {
      display: "flex",
      justifyContent: "flex-start", // NOSONAR propriedade CSS.
      gap: "16px",

      button: {
        padding: "16px",
      },

      ".secondary": {
        color: color.brandSecondary.light,
        borderColor: color.brandSecondary.light,
        borderRadius: "4px",

        "&:hover": {
          color: color.neutral["08"],
          border: `1px solid ${color.brandSecondary.light}`,
          background: color.brandSecondary.gradient,
        },
      },

      ".no-style": {
        fontFamily: "Raleway",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "150%",
        color: "#002364",
        border: "solid 1px transparent",
        order: 0,
        margin: "0px 0px",
        flex: "1 1 0%",
        "&:hover": {
          fontFamily: "Raleway",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "150%",
          color: "#002364",
          background: "transparent",
          border: "solid 1px #002364",
          order: 0,
          margin: "0px 0px",
        },
      },

      ".single": {
        maxWidth: "250px",
      },
    },

    'div[class^="Spinnerstyle__Spinner"]': {
      display: "inline-flex",
      width: "16px",
      height: "16px",
      marginRight: "8px",
      position: "relative",
      top: "3px",

      div: {
        div: {
          width: "16px",
          height: "16px",
          borderWidth: "2px",
        },
      },
    },
  })
);

export const Lead = styled(Text)(({ theme }) => ({
  color: theme.color.neutral["03"],
}));

export const Title = styled(Text)(({ theme, smallTitle }) => ({
  color: theme.color.neutral["02"],
  textTransform: "none",
  fontSize: smallTitle ? "24px" : "28px",
}));

export const SubTitle = styled(Text)(({ theme }) => ({}));
export const List = styled(Text)(({ theme }) => ({}));
export const ListItem = styled(Text)(({ theme }) => ({}));

export const Description = styled.div(({ theme }) => ({
  color: theme.color.neutral["02"],
  fontSize: "20px",
}));

export const Caption = styled.div(({ theme }) => ({
  color: theme.color.neutral["02"],
  fontWeight: "bold",
  fontSize: "20px",
}));
