import React, { useContext } from "react";
import * as S from "./DownloadsDocumentos.style.js";
import DownloadIcon from "../../assets/svgs/download-icon.svg";
import envs from "config/envs";
import "./../../styles/shared.scss";
import OdontoContext from "context/OdontoContext.js";

const DonwloadsDocumentos = () => {
  const { percentageZoom } = useContext(OdontoContext);
  return (
    <section>
      <S.DocumentSection zoom={percentageZoom}>
        <S.TitleDocuments zoom={percentageZoom}>Documentos</S.TitleDocuments>
        <S.DocumentsDescription zoom={percentageZoom}>
          Para saber tudo sobre o seu Plano Odonto Sigma, baixe os documentos de
          cobertura completa e a condição geral do seu produto.
        </S.DocumentsDescription>
        <S.LinksDownloadContainer zoom={percentageZoom}>
          <S.DownloadContent zoom={percentageZoom}>
            <img src={DownloadIcon} alt={"Icone de download de documentos"} />
            <a
              href={`${envs.hostStaticCdn}/Odonto/PlanoOdontoSigma/30_-_cobertura_nacional_plano_sigma_v3%20(1).pdf`}
              target="_blank"
              rel="noreferrer"
            >
              Cobertura completa
            </a>
          </S.DownloadContent>
          <S.DownloadContent zoom={percentageZoom}>
            <img src={DownloadIcon} alt={"Icone de download de documentos"} />
            <a
              href={`${envs.hostStaticCdn}/Odonto/PlanoOdontoSigma/157-Guia-benefici%C3%A1rio-Correios.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              Condições Gerais
            </a>
          </S.DownloadContent>
          <S.DownloadContent zoom={percentageZoom}>
            <img src={DownloadIcon} alt={"Icone de download de documentos"} />
            <a
              href={`${envs.hostStaticCdn}/Odonto/PlanoOdontoSigma/157-Guia-benefici%C3%A1rio-Correios%20SEM%20CARENCIA.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              Condições gerais carência zero
            </a>
          </S.DownloadContent>
          <S.DownloadContent zoom={percentageZoom}>
            <img src={DownloadIcon} alt={"Icone de download de documentos"} />
            <a
              href={`${envs.hostStaticCdn}/Odonto/PlanoOdontoSigma/039%20regulamento_CAP_v5.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              Regulamento do seu número da sorte
            </a>
          </S.DownloadContent>
        </S.LinksDownloadContainer>
      </S.DocumentSection>
    </section>
  );
};

export default DonwloadsDocumentos;
