import { useContext } from "react";
import * as S from "./Content.style";
import OdontoContext from "context/OdontoContext";

const Content = ({ children, ...props }) => {
  const { percentageZoom } = useContext(OdontoContext);
  return (
    <S.Content zoom={percentageZoom} {...props}>
      {children}
    </S.Content>
  );
};

export default Content;
