import React from "react";
import ResponsiveCard from "../ResponsiveCard";
import * as S from "./ExercaSeusDireitos.style";

function ExercaSeusDireitos() {
  return (
    <S.Gapping id="Exerçaseusdireitos">
      <ResponsiveCard
        title="Exerça Seus Direitos"
        maxContent
        customSection={
          <>
            {/* <StatusTitle title="E-mail enviado com sucesso." /> */}
            <S.TextStyled variant="body-02-md">
              A gente leva a sério a proteção de seus dados e, por isso, existe
              um Portal de Privacidade dedicado a esse assunto, para que você
              conheça mais seus direitos e os exerça.
            </S.TextStyled>
            <S.PStyled>
              Para exercer seu direito ou de terceiros, envie um e-mail para
              &nbsp;
              <a href="mailto:privacidade@odontoempresas.com.br">
                privacidade@odontoempresas.com.br
              </a>
              .
            </S.PStyled>
            <S.TextStyled>
              É importante que no e-mail você especifique para a gente o seu
              pedido, dentre os quais:
            </S.TextStyled>
            <S.ContainerUL>
              <li>Saber quais dados meus vocês têm;</li>
              <li>Corrigir meus dados;</li>
              <li>Portar meus dados para outra empresa;</li>
              <li>Excluir meus dados;</li>
              <li>Me opor ao tratamento de algum dado</li>
            </S.ContainerUL>
            <S.TextStyled>
              Informamos que, para dar respostas adequadas e corretas, a gente
              precisa de até 15 dias para responder a solicitações de acesso aos
              seus dados e de até 30 dias para responder outros tipos de
              pedidos, contados a partir da sua solicitação. Tranquilize-se, seu
              pedido não fica sem resposta.
            </S.TextStyled>
            <S.TextStyled>
              Lembre-se: para garantir sua identidade e que seu pedido é
              legitimo, é necessário que você informe para a gente seus dados
              pessoais e encaminhe documentos que auxiliam no processo de
              autenticação. Esses dados ficarão armazenados em bases de dados da
              Odonto Empresas para possíveis demandas legais e regulatórias,
              comprovando que seu pedido foi feito e atendido. Seus dados não
              serão utilizados para outros fins.
            </S.TextStyled>
            <S.Container>
              <S.Title>Para exercer seus direitos</S.Title>
              <S.PStyled>
                Se for exercer um direito seu, é importante que, além de
                informar seus dados pessoais, você também envie alguns
                documentos necessários, por foto ou digitalizados.
              </S.PStyled>
              <br />
              <S.PStyled>
                Esses dados e documentos auxiliam a gente no processo de
                autenticação, evitando possíveis fraudes e o acesso indevido às
                suas informações.
              </S.PStyled>
              <S.PStyled>
                <li>
                  Documento de identificação com foto (CNH, RG, passaporte,
                  carteira de identidade profissional ou carteira de trabalho);
                </li>
                <br />
                <li>
                  Comprovante de residência (conta de água, luz, internet ou
                  telefone) – caso você não tenha um comprovante de residência
                  em seu nome, inclua o comprovante disponível e justifique no
                  campo específico o motivo de o documento não estar em seu
                  nome.
                </li>
              </S.PStyled>
            </S.Container>
            <S.Container>
              <S.Title>Para exercer direitos para outra pessoa</S.Title>
              <S.PStyled>
                Se for solicitar um direito em nome de outra pessoa, é
                importante que, além de informar os seus dados pessoais e os da
                pessoa, você também envie alguns documentos necessários, por
                foto ou digitalizados.
              </S.PStyled>
              <br />
              <b>De quem solicita</b>
              <br />
              <br />
              <li>
                Documento de identificação com foto (CNH, RG, passaporte,
                carteira de identidade profissional ou carteira de trabalho);
              </li>
              <br />
              <li>
                Comprovante de residência (conta de água, luz, internet ou
                telefone) – caso você não tenha um comprovante de residência em
                seu nome, inclua o comprovante disponível e justifique no campo
                a seguir o motivo de o documento não estar em seu nome.
              </li>
              <br />
              <b>Da outra pessoa</b> <br />
              <br />
              <li>
                Documento de identificação com foto (CNH, RG, passaporte,
                carteira de identidade profissional ou carteira de trabalho);
              </li>
              <br />
              <li>
                Procuração ou outro documento oficial que comprove que você é o
                responsável por essa outra pessoa.
              </li>
            </S.Container>
          </>
        }
      />
    </S.Gapping>
  );
}

export default ExercaSeusDireitos;
