import { Text } from "@ds/react";
import styled from "styled-components";

export const Containerinformation = styled.div(({ theme, zoom }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  padding: "40px 16px",

  [theme.breakpoint.lg()]: {
    padding: zoom < 120 ? "0px 113px 50px 113px" : "40px 16px",
    marginBottom: zoom < 120 && "30px",
  },
}));

export const Titlebeneficios = styled.div(({ theme, zoom }) => ({
  color: "#333333",

  [theme.breakpoint.lg()]: {
    paddingTop: zoom < 120 && "20px",
    marginBottom: zoom < 120 && "24px",
  },
}));

export const Titulobeneficios = styled.p(({ theme, zoom }) => ({
  marginTop: "0",
  fontSize: "28px",
  lineHeight: "150%",
  fontWeight: "700",

  [theme.breakpoint.lg()]: {
    fontSize: zoom < 120 ? "32px" : "28px",
    marginBottom: zoom < 120 && "16px",
  },
}));

export const Description = styled.p(({ theme, zoom }) => ({
  fontSize: "18px",
  fontWeight: "500",
  lineHeight: "160%",

  [theme.breakpoint.lg()]: {
    fontSize: zoom < 120 ? "20px" : "18px",
    margin: zoom < 120 && "0",
  },
}));

export const RewardsListInformation = styled.div(({ theme, zoom }) => ({
  display: "flex",
  gap: "32px",
  flexDirection: "column",

  [theme.breakpoint.lg()]: {
    flexDirection: zoom < 120 ? "row" : "column",
    fontSize: zoom < 120 && "10px",
  },
}));

export const RewardItem = styled.div({
  borderLeft: "2px solid #d70064",
  paddingLeft: "20px",
  width: "80%",
});

export const RewardItemBodyInformation = styled.p(({ theme, zoom }) => ({
  color: "#001845",
  fontSize: "14px",
  marginBottom: "0",
  lineHeight: "21px",
  fontWeight: "500",

  [theme.breakpoint.lg()]: {
    fontSize: zoom < 120 ? "16px" : "14px",
    width: zoom < 120 && "92%",
    lineHeight: zoom < 120 ? "24px" : "21px",
  },

  a: {
    color: "#001845",
  },
}));

export const TitleDisclaimer = styled.div(({ theme }) => ({
  alignItems: "center",
  marginBottom: "10px",

  hr: {
    color: "#fff",
    backgroundColor: "#fff",
    border: "none",
    height: "15px",
    marginLeft: "-18px",
    marginRight: "-18px",
  },
}));

export const SectionTitle = styled(Text)(({ theme, zoom }) => ({
  color: "#ffffff",
  fontWeight: "bold",
  background: "#d70064",
  padding: "8px 16px",
  width: "fit-content",
  margin: "6px 0",
  fontSize: "28px",
  lineHeight: "150%",
  textTransform: "none",
  display: "block",

  [theme.breakpoint.lg()]: {
    position: zoom < 120 && "relative",
    right: zoom < 120 && "50px",
    marginLeft: zoom < 120 && "50px",
    fontSize: zoom < 120 && "32px",
    marginTop: zoom < 120 && "80px",
  },
}));
