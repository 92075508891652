import { Text } from "@ds/react";
import styled from "styled-components";

export const TextStyled = styled(Text).attrs({ variant: "body-02-sm" })(() => ({
  fontWeight: 500,
  width: "100%",
}));

export const ListStyle = styled.li(() => ({
  color: "#D70064",
  fontWeight: 700,
}));
export const PaddingBox = styled.div(() => ({
  paddingLeft: "24px",
}));
export const Section = styled.section(() => ({
  backgroundColor: "red",
}));

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 20px 20px rgba(45, 41, 38, 0.08)",
  background: theme.color.neutral["08"],
  marginBottom: "16px",

  [theme.breakpoint.lg()]: {
    marginBottom: "20px",
    flexDirection: "row",
  },
}));
