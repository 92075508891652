import Banner from "components/banner";
import DesktopFallbackImagePU from "assets/svgs/PortalPrivacidadeDesktop.png";
import FallbackImagePU from "assets/svgs/PortalPrivacidadeMobile.png";
import DesktopImagePU from "assets/svgs/PortalPrivacidadeDesktop.webp";
import ImagePU from "assets/svgs/PortalPrivacidadeMobile.webp";
import { useTheme } from "@ds/react";
import { getQuery } from "utils/mediaQuery";
import styled from "styled-components";
import NavBar from "components/NavBar";

const StyledButton = styled.button`
  background-color: white;
  color: #002364;
  border: 1px solid #002364;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding: 16px 36px;
  border-radius: 4px;
  @media (max-width: 768px) {
    width: 100%;
  }
  &:hover {
    background-color: #002364;
    color: white;
    cursor: pointer;
  }
`;
const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 20px 20px rgba(45, 41, 38, 0.08)",
  background: theme.color.neutral["08"],
  marginBottom: "16px",

  [theme.breakpoint.lg()]: {
    marginBottom: "20px",
    // flexDirection: "row",
  },
}));

// const ImageBanner = styled.img(({theme}) => ({
//   [theme.breakpoint.lg()]:{
//     height:
//   }
// }))

const PortalPrivacidadeBanner = () => {
  const theme = useTheme();
  const bannerImagePU = (
    <picture>
      <source
        srcSet={DesktopImagePU}
        media={getQuery(theme.breakpoint.lg())}
        type="image/webp"
      />
      <source
        srcSet={DesktopFallbackImagePU}
        media={getQuery(theme.breakpoint.lg())}
        type="image/jpeg"
      />
      <source srcSet={ImagePU} type="image/webp" />
      <img
        style={{ height: "100%" }}
        src={FallbackImagePU}
        alt="banner cliente cap-vencedor"
      />
    </picture>
  );

  return (
    <Container>
      <Banner image={bannerImagePU} fullWidth>
        <Banner.Content
          title="Proteger seus dados também é um jeito de cuidar de você."
          titleColor={"#002364"}
          description="Entenda como coletamos e armazenamos seus dados pessoais."
          descriptionColor={"#002364"}
        />
        <StyledButton
          target="_blank"
          href="https://mais.correios.com.br/app/index.php"
          variant="body-01-md"
          color={theme.color.brandPrimary.light}
        >
          Saber mais
        </StyledButton>
      </Banner>
      <NavBar />
    </Container>
  );
};

export default PortalPrivacidadeBanner;
