import styled from "styled-components";

export const ContainerDoubts = styled.section(({theme, zoom}) => ({
  color: '#454E4F',
  backgroundColor: '#fff',
  padding: '40px 16px',
  a: {
    color: '#002364',
  },
  [theme.breakpoint.lg()]:{
    padding: zoom > 130 ? '40px 16px' : '60px 112px',
  }
}))

export const TakeQuestion = styled.h2(({theme, zoom}) => ({
  lineHeight: '42px',
  width: '80%',
  fontSize: '40px',
  paddingBottom: '10px',
  marginTop: 0,
  [theme.breakpoint.lg()]:{ 
    fontSize: zoom > 130 ? '28px' : '40px',
  }
}))
export const Questions = styled.h3(({theme, zoom}) => ({
  fontSize: '20px',
  paddingBottom: '10px',
  fontWeight: 700,
  lineHeight: '30px',
  color: '#002364',
  [theme.breakpoint.lg()]:{  
    fontSize: zoom > 130 ? '20px' : '24px',
    lineHeight: zoom > 130 ? '30px' : '36px',
  }
}))

export const SectionCollapse = styled.div(({theme, zoom}) => ({
  display: 'block',
  justifyContent:'center',
  width: '100%',
  [theme.breakpoint.lg()]: {
    display: zoom > 130 ? 'block' : 'flex',
    justifyContent: zoom > 130 ? 'center' : 'space-between',
    div:{
      width: zoom > 130 && '100%', 
    }
  },
}))
