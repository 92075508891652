import styled from "styled-components";

export const UlStyled = styled.ul(({ theme }) => ({
  display: "none",
  [theme.breakpoint.lg()]: {
    display: "flex",
    flexDirection: "row",
    listStyleType: "none",
    alignItems: "center",
    padding: 0,
    overflow: "hidden",
    backgroundColor: "white",
    height: "98px",
    justifyContent: "space-around",
    margin: "0 50px",
  },
}));

export const LiStyled = styled.li(() => ({
  float: "left",
  "li, a": {
    display: "block",
    color: "#666",
    textAlign: "center",
    padding: "14px 16px",
    textDecoration: "none",
  },

  "li, a:hover:not(.active)": {
    // color: "white",
    // backgroundColor: "#002364",
    borderBottom: "5px solid #002364",
    alignItems: "flex-end",
    height: "37px",
    marginTop: "27px",
  },

  "li, a.active": {
    color: "#000",
    borderBottom: "4px solid #002364",
    alignItems: "flex-end",
    height: "39px",
    marginTop: "22px",
  },
}));
