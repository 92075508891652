import { Text } from "@ds/react";
import React from "react";
import ResponsiveCard from "../ResponsiveCard";
import * as S from "./PersonalizarCookies.style";
import CardDetail from "components/CardDetail";

function PersonalizarCookies() {
  return (
    <S.Container id="Personalizarcookies">
      <ResponsiveCard
        title="Personalizar Cookies"
        maxContent
        customSection={
          <S.Box>
            <Text variant="body-02-md">
              Personalize os seus cookies no site da empresa.
            </Text>
            <S.CardsContainer>
              <CardDetail
                title="Odonto Empresas"
                link={"https://odontoempresas.com.br/"}
              />
              <CardDetail
                title="Rede Odonto Empresas"
                link={"https://redeodontoempresas.com.br/"}
              />
              <CardDetail
                title="Plano Odonto Sigma"
                link={"https://www.planoodontosigma.com.br/"}
              />
            </S.CardsContainer>
          </S.Box>
        }
      />
    </S.Container>
  );
}

export default PersonalizarCookies;
