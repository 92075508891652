import React, { useContext } from "react";
import * as S from "./specialty.style";

import Photo from "./../../assets/images/photoservice.png";
import OdontoContext from "context/OdontoContext";
import { useDeviceType } from "@ds/react";

const Specialty = () => {
  const { percentageZoom } = useContext(OdontoContext);
  const { xl } = useDeviceType();
  return (
    <section>
      <div
        className="make-section"
        data-section="coberturas section"
        id="coberturas"
      >
        <S.Coberturas zoom={percentageZoom}>
          <S.Specialty zoom={percentageZoom}>
            <div>
              {xl && percentageZoom < 120 ? (
                <S.ImageContainer>
                  <img src={Photo} className="DesktopOnly" alt="" />
                  <S.TitleContainer className="DesktopOnly">
                    <S.Titlespecialty primaryColor zoom={percentageZoom}>
                      Você já está ganhando
                    </S.Titlespecialty>
                    <S.Titlespecialty zoom={percentageZoom}>
                      Com nossa cobertura, você realiza estes serviços:
                    </S.Titlespecialty>
                  </S.TitleContainer>
                </S.ImageContainer>
              ) : (
                <div>
                  <img src={Photo} alt="" />
                  <S.SorriaText zoom={percentageZoom}>
                    <S.Titlespecialty primaryColor zoom={percentageZoom}>
                      Você já está ganhando
                    </S.Titlespecialty>

                    <S.Titlespecialty zoom={percentageZoom}>
                      Com nossa cobertura, você
                    </S.Titlespecialty>
                    <S.Titlespecialty zoom={percentageZoom}>
                      realiza estes serviços:
                    </S.Titlespecialty>
                  </S.SorriaText>
                </div>
              )}
            </div>
          </S.Specialty>
          <S.Container zoom={percentageZoom}>
            <S.RewardsList zoom={percentageZoom}>
              <S.RewardItem zoom={percentageZoom}>
                <S.Nomeprocedimento>Urgência</S.Nomeprocedimento>
                <S.RewardItemBody zoom={percentageZoom}>
                  Atendimento em caso de dor aguda difícil de suportar.
                </S.RewardItemBody>
              </S.RewardItem>
              <S.RewardItem zoom={percentageZoom}>
                <S.Nomeprocedimento>Prevenção</S.Nomeprocedimento>
                <S.RewardItemBody zoom={percentageZoom}>
                  Avaliação geral da saúde bucal. Inclui limpeza e aplicação de
                  flúor.
                </S.RewardItemBody>
              </S.RewardItem>
              <S.RewardItem gap={true} zoom={percentageZoom}>
                <S.Nomeprocedimento>Dentística</S.Nomeprocedimento>
                <S.RewardItemBody zoom={percentageZoom}>
                  Tratamento de cáries e restaurações.
                </S.RewardItemBody>
              </S.RewardItem>
            </S.RewardsList>
            <S.RewardsList zoom={percentageZoom}>
              <S.RewardItem zoom={percentageZoom}>
                <S.Nomeprocedimento>Periodontia</S.Nomeprocedimento>
                <S.RewardItemBody zoom={percentageZoom}>
                  Tratamento de gengiva e de infecções do tecido bucal.
                </S.RewardItemBody>
              </S.RewardItem>
              <S.RewardItem zoom={percentageZoom}>
                <S.Nomeprocedimento>Próteses</S.Nomeprocedimento>
                <S.RewardItemBody zoom={percentageZoom}>
                  Coroas (novo rol da ANS).
                </S.RewardItemBody>
              </S.RewardItem>
              <S.RewardItem gap={true} zoom={percentageZoom}>
                <S.Nomeprocedimento>Odontopediatria</S.Nomeprocedimento>
                <S.RewardItemBody zoom={percentageZoom}>
                  Tratamento para crianças, desde o nascimento.
                </S.RewardItemBody>
              </S.RewardItem>
            </S.RewardsList>
            <S.RewardsList zoom={percentageZoom}>
              <S.RewardItem zoom={percentageZoom}>
                <S.Nomeprocedimento>Cirurgia</S.Nomeprocedimento>
                <S.RewardItemBody zoom={percentageZoom}>
                  Extração de dentes, incluindo o dente do siso.
                </S.RewardItemBody>
              </S.RewardItem>
              <S.RewardItem zoom={percentageZoom}>
                <S.Nomeprocedimento>Endodontia</S.Nomeprocedimento>
                <S.RewardItemBody zoom={percentageZoom}>
                  Tratamento de canal, das lesões e doenças da polpa (nervo) e
                  da raiz do dente.
                </S.RewardItemBody>
              </S.RewardItem>
              <S.RewardItem zoom={percentageZoom}>
                <S.Nomeprocedimento>Aparelho Ortodôntico</S.Nomeprocedimento>
                <S.RewardItemBody zoom={percentageZoom}>
                  Inclui confecção e instalação do aparelho ortodôntico. Não
                  inclui manutenção ortodôntica mensal. Documentação de acordo
                  com a cobertura do plano.
                </S.RewardItemBody>
              </S.RewardItem>
            </S.RewardsList>
          </S.Container>
        </S.Coberturas>
      </div>
    </section>
  );
};

export default Specialty;
