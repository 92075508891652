import Content from "components/Content";
import * as S from "./Header.style";
import { useState } from "react";
import SideMenu from "components/SideMenu";
import "./../../styles/shared.scss";
import HeaderPortalPrivacidades from "./HeaderPortalPrivacidade/HeaderPortalPrivacidade";
import CommonHeader from "./CommonHeader/CommonHeader";

const Header = ({ PortalPrivacidade }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <S.Header borderColor="#E3E0E2">
        <Content noPadding>
          {PortalPrivacidade ? <HeaderPortalPrivacidades /> : <CommonHeader />}
          <SideMenu open={open} handleOpen={handleOpen} />
        </Content>
      </S.Header>
    </>
  );
};

export default Header;
