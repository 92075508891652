import React from "react";
import { useDeviceType } from "@ds/react";
import MenuItem from "./MenuItem";
import * as S from "./MenuList.style";
import Button from "@ds/react/components/Button";

const MenuList = ({ setOpen }) => {
  const { xl, sm } = useDeviceType();

  return (
    <nav aria-label="Breadcrumb">
      <S.MenuList>
        <MenuItem label="Benefícios" path="#beneficios" setOpen={setOpen} />
        <MenuItem label="Coberturas" path="#coberturas" setOpen={setOpen} />
        <MenuItem label="Encontre um dentista" path="#encontre-um-dentista" setOpen={setOpen} />
        <MenuItem label="Ajuda" path="#ajuda" setOpen={setOpen} />
        <MenuItem label="Fale com a gente" path="#fale-com-a-gente" setOpen={setOpen} />

        {!xl && sm && (
          <>
            <Button
              role="button"
              onClick={() =>
                window.open(
                  "https://www.odontoutilis.com.br/ConnectOdontoWeb/websegurado-index-login.action",
                  "_blank"
                )
              }
            >
              Login
            </Button>
          </>
        )}
      </S.MenuList>
    </nav>
  );
};

export default MenuList;
