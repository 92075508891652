import styled from "styled-components/macro";

export const Container = styled.div(
  ({ theme: { color, spacing, breakpoint }, zoom }) => ({
    display: "flex",
    marginTop: spacing.stack.xs,
    padding: 0,
    flexDirection: "column",
    justifyContent: "flex-start",
    color: color.neutral["06"],
    p: {
      display: "block",
      fontWeight: '400',
    },
    "> *": {
      justifyContent: "space-between",
      marginBottom: spacing.stack["2xs"],

      ":last-child": {
        marginBottom: 0,
      },
    },

    [breakpoint.xl()]: {
      "&.copyright": {
        display: zoom < 120 && "flex",
        // flexDirection: zoom < 120 && "row",
        justifyContent: zoom < 120 && "space-between",
      },
    },
  })
);
