import React from "react";
import * as S from "./NavBar.style";

function NavBar() {
  return (
    <>
      <S.UlStyled>
        <S.LiStyled>
          <a href="#Políticadeprivacidade">Política de privacidade</a>
        </S.LiStyled>
        <S.LiStyled>
          <a href="#Políticadecookies">Política de cookies</a>
        </S.LiStyled>
        <S.LiStyled>
          <a href="#Personalizarcookies">Personalizar cookies</a>
        </S.LiStyled>
        <S.LiStyled>
          <a href="#Seusdireitos">Seus direitos</a>
        </S.LiStyled>
        <S.LiStyled>
          <a href="#Exerçaseusdireitos">Exerça seus direitos</a>
        </S.LiStyled>
      </S.UlStyled>
    </>
  );
}

export default NavBar;
