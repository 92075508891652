import styled from "styled-components";

export const Footer = styled.div(({ theme }) => ({
  backgroundColor: "#000D26",
  padding: "0 28px",
  height: "104px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "28px",
  [theme.breakpoint.lg()]: {
    padding: "0 112px",
  },
}));
