import React, { useContext } from "react";
import { ReactComponent as Tooth } from "./../../assets/svgs/tooth.svg";
import { ReactComponent as Dependent } from "./../../assets/svgs/dependent.svg";
import { ReactComponent as Phone } from "./../../assets/svgs/mobile-phone.svg";
import { ReactComponent as Luck } from "./../../assets/svgs/luck.svg";
import * as S from "./homeInformation.style";
import OdontoContext from "context/OdontoContext";
import { useDeviceType } from "@ds/react";

const HomeInformation = () => {
  const { percentageZoom } = useContext(OdontoContext);
  const { xl } = useDeviceType();

  return (
    <section id="beneficios">
      <S.Containerinformation zoom={percentageZoom}>
        {xl && percentageZoom < 120 ? (
          <div>
            <S.TitleDisclaimer>
              <S.SectionTitle zoom={percentageZoom}>
                Sorria muito, pague pouco ;)
              </S.SectionTitle>
            </S.TitleDisclaimer>
          </div>
        ) : (
          <div>
            <S.TitleDisclaimer>
              <S.SectionTitle variant="headline-02">
                Sorria muito,
                <hr />
                pague pouco ;)
              </S.SectionTitle>
            </S.TitleDisclaimer>
          </div>
        )}
        <S.Titlebeneficios zoom={percentageZoom}>
          <S.Titulobeneficios zoom={percentageZoom}>
            Apenas R$ 36,90/mês por pessoa{" "}
          </S.Titulobeneficios>
          <S.Description zoom={percentageZoom}>
            Confira os principais benefícios do nosso plano:
          </S.Description>
        </S.Titlebeneficios>
        <S.RewardsListInformation zoom={percentageZoom}>
          <S.RewardItem>
            <Tooth alt="" width="28px" />
            <S.RewardItemBodyInformation zoom={percentageZoom}>
              Saúde odontológica com melhor custo/benefício, livre de franquias,
              sem limite de utilização e{" "}
              <strong>com instalação de aparelho ortodôntico.</strong>
            </S.RewardItemBodyInformation>
          </S.RewardItem>
          <S.RewardItem>
            <Dependent alt="" width="28px" />
            <S.RewardItemBodyInformation zoom={percentageZoom}>
              Você pode incluir dependentes sem grau de parentesco.{" "}
              <strong>Crianças de 0 a 3 anos não pagam</strong> (solicite
              exclusivamente pela Central de Atendimento).
            </S.RewardItemBodyInformation>
          </S.RewardItem>
          <S.RewardItem>
            <Phone width="28px" />
            <S.RewardItemBodyInformation zoom={percentageZoom}>
              Mais uma opção para encontrar a rede credenciada. Envie um{" "}
              <strong>
                <a href="sms://29734?&body=ODONTO SEU-CEP">SMS para 29734</a>
              </strong>{" "}
              com a mensagem: ODONTO e informe seu CEP, ex: ODONTO 70701050
            </S.RewardItemBodyInformation>
          </S.RewardItem>
          <S.RewardItem>
            <Luck alt="" width="28px" />
            <S.RewardItemBodyInformation zoom={percentageZoom}>
              Receba o número da sorte e{" "}
              <strong>concorra todo mês a R$ 10 mil</strong> pela Loteria
              Federal, por 12 meses, enquanto estiver em dia. E ainda deduza do
              IR.
            </S.RewardItemBodyInformation>
          </S.RewardItem>
        </S.RewardsListInformation>
      </S.Containerinformation>
    </section>
  );
};

export default HomeInformation;
