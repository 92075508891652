import { Text } from "@ds/react";
import styled from "styled-components/macro";

export const Items = styled.ul(({ theme: { breakpoint, spacing }, zoom }) => ({
  display: "flex",
  flexDirection: "column",
  padding: 0,
  margin: `${spacing.stack.xs} 0`,
  listStyle: "none",
  flexWrap: "nowrap",
  gap: spacing.stack["3xs"],

  [breakpoint.lg()]: {
    flexDirection: zoom < 120 && "row",
    gap: zoom < 120 && spacing.stack["2xl"],
    alignItems: zoom < 120 && "center",
  },
}));

export const Item = styled.li({
  padding: 0,
  a: { color: "#FFFFFF", fontWeight: 500 },
});

export const Logos = styled.div(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "18px",
  margin: `${spacing.stack.md} 0 12px`,

  "> div": {
    display: "flex",
    alignItems: "baseline",
    gap: "60px",
  },
}));

export const LinkStyledButton = styled.button({
  background: "none!important",
  border: "none",
  padding: "0",
  textDecoration: "underline",
  cursor: "pointer",
  fontSize: "16px",
  color: "#FFFFFF",
  fontWeight: 500,
});

export const RedesSociais = styled.div(({ theme: { breakpoint }, zoom }) => ({
  backgroundColor: "#001845",

  [breakpoint.lg()]: {
    display: zoom < 120 && "flex",
    flexDirection: zoom < 120 && "row",
    gap: zoom < 120 && "100px",
  },
}));
export const ItemsComunicacao = styled.div({
  listStyle: "none",
});

export const ItemComunicacao = styled.button({
  backgroundColor: "#001845",
  border: "none",
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  marginBottom: "20px",
});

export const ParceriaText = styled(Text)(({ theme }) => ({
  color: theme.color.neutral["05"],
}));
