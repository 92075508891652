import logoCorreios from "assets/svgs/Logo-Correios.svg";
import logoOdonto from "assets/svgs/LogoOdontoEmpresas.svg";
import logoCNP from "assets/svgs/Logo-CNP-Seguradora-Negative.svg";

import { ReactComponent as Instagram } from "assets/svgs/instagram.svg";
import { ReactComponent as Facebook } from "assets/svgs/facebook.svg";
import { ReactComponent as Linkedin } from "assets/svgs/linkedin.svg";

import { ReactComponent as AppStore } from "assets/svgs/logo-appstore.svg";
import { ReactComponent as GooglePlay } from "assets/svgs/logo-googleplay.svg";

import { ReactComponent as AnsLogo } from "assets/svgs/ans-logo.svg";
import { ReactComponent as AnsNumero } from "assets/svgs/ANS-Number.svg";

import * as S from "./LinksSection.style";
import { Divider } from "@ds/react";
import { useContext } from "react";
import OdontoContext from "context/OdontoContext";

const LinksSection = () => {
  const { percentageZoom } = useContext(OdontoContext);
  return (
    <>
      <S.Logos>
        <S.ParceriaText variant="caption-02"></S.ParceriaText>
        <div>
          <img src={logoOdonto} alt="Logo odonto" />
          <img height={60} src={logoCNP} alt="logo cnp seguros holding" />
          <img height={32} src={logoCorreios} alt="Logo correios" />
        </div>
      </S.Logos>
      <S.Items zoom={percentageZoom}>
        {/* <S.Item>
          <a
            role="button"
            href={"AA_CNP_BRASIL"}
            target="_blank"
            rel="noreferrer"
            className="make-click"
          >
            Sobre a CNP Brasil
          </a>
        </S.Item> */}
        <S.Item>
          <a
            role="button"
            href="https://odontoempresas.com.br/"
            target="_blank"
            rel="noreferrer"
            className="make-click"
          >
            Odonto Empresas
          </a>
        </S.Item>
        <S.Item data-label="cookies link">
          <S.LinkStyledButton
            role="button"
            className="make-click"
            onClick={() => window.cnp.performance.consent.modal()}
          >
            Cookies
          </S.LinkStyledButton>
        </S.Item>
        <S.Item>
          <a
            role="button"
            href={"portalprivacidade"}
            target="_blank"
            rel="noreferrer"
            className="make-click"
          >
            Política de Privacidade
          </a>
        </S.Item>
        <S.Item>
          <a
            role="button"
            href={"https://www.contatoseguro.com.br/pt/odontoempresas"}
            target="_blank"
            rel="noreferrer"
            className="make-click"
          >
            Canal de ética
          </a>
        </S.Item>
      </S.Items>
      <Divider />
      <S.RedesSociais zoom={percentageZoom}>
        <S.ItemsComunicacao zoom={percentageZoom}>
          <p>Redes sociais</p>
          <S.ItemComunicacao>
            <S.Item>
              <a
                role="button"
                href="https://www.facebook.com/odontoempresasoficial"
                target="_blank"
                rel="noreferrer"
                className="make-click"
              >
                <Facebook alt="Logo facebook" />
              </a>
            </S.Item>
            <S.Item>
              <a
                role="button"
                href="https://www.instagram.com/odontoempresas"
                target="_blank"
                rel="noreferrer"
                className="make-click"
              >
                <Instagram alt="logo instagram" />
              </a>
            </S.Item>

            <S.Item>
              <a
                role="button"
                href="https://br.linkedin.com/company/odonto-empresas"
                target="_blank"
                rel="noreferrer"
                className="make-click"
              >
                <Linkedin alt="logo linkedin" />
              </a>
            </S.Item>
          </S.ItemComunicacao>
        </S.ItemsComunicacao>
        <S.ItemsComunicacao zoom={percentageZoom}>
          <p>Baixe o aplicativo</p>
          <S.ItemComunicacao>
            <S.Item>
              <a
                role="button"
                href="https://apps.apple.com/br/app/odonto-empresas/id1346156727"
                target="_blank"
                rel="noreferrer"
                className="make-click"
              >
                <AppStore alt="Logo AppStore" />
              </a>
            </S.Item>
            <S.Item>
              <a
                role="button"
                href="https://play.google.com/store/apps/details?id=br.com.caixaseguradoraodonto.segurado"
                target="_blank"
                rel="noreferrer"
                className="make-click"
              >
                <GooglePlay alt="logo GooglePlay" />
              </a>
            </S.Item>
          </S.ItemComunicacao>
        </S.ItemsComunicacao>

        <S.ItemsComunicacao zoom={percentageZoom}>
          {" "}
          <br />
          <AnsLogo alt="Logo ANS" />
          <S.ItemComunicacao>
            <S.Item>
              <AnsNumero alt="logo intagram" />
            </S.Item>
          </S.ItemComunicacao>
        </S.ItemsComunicacao>
      </S.RedesSociais>
    </>
  );
};

export default LinksSection;
