import styled, { keyframes, css } from "styled-components";

const rotate = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const rotateAnimation = () => css`
  animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
`;

export const Spinner = styled.div(({ theme: { icon } }) => ({
  display: "block",
  width: icon.size.sm,
  height: icon.size.sm,
}));

const border = (theme) =>
  `${theme.border.width.lg} solid ${theme.color.neutral["08"]}`;

export const Ring = styled.div`
  display: inline-block;
  position: absolute;
  margin: 0;
  padding: 0;

  div {
    display: block;
    position: absolute;
    width: ${({ theme }) => theme.icon.size.sm};
    height: ${({ theme }) => theme.icon.size.sm};
    border: ${({ theme }) => border(theme)};
    border-radius: ${({ theme }) => theme.border.radius.circular};
    border-color: ${({ theme }) => theme.color.brandSecondary.light} transparent
      transparent transparent;
    box-sizing: border-box;

    ${rotateAnimation()}
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
