import { Text } from "@ds/react";
import React, { useState } from "react";
import CollapseItem from "../CollapseItem/CollapseItem";
import ResponsiveCard from "../ResponsiveCard";
import * as S from "./SeusDireitos.style";

function SeusDireitos() {
  const [currentItem, setCurrentItem] = useState(-1);

  return (
    <S.Container id="Seusdireitos">
      <ResponsiveCard
        title="Seus Direitos"
        maxContent
        customSection={
          <>
            {/* <StatusTitle title="E-mail enviado com sucesso." /> */}
            <Text variant="body-02-md">
              A LGPD garante uma série de direitos relacionados à proteção dos
              seus dados pessoais, para que suas escolhas sejam respeitadas e
              para que você tenha o controle de como são utilizadas suas
              informações. A seguir, a gente descreve quais são eles e como você
              pode exercê-los aqui.
            </Text>

            <CollapseItem
              question="Direito de acesso"
              answer={`O direito de saber se a gente tem algum dado pessoal seu e de acessar esses dados. Quando você faz a solicitação, a Odonto Empresas envia, em até 15 dias, um relatório de quais dos seus dados pessoais estão com a gente. 
              É mostrada, ainda, a finalidade de utilização dos dados, como estão sendo utilizados e com quais empresas, órgãos e entidades estão sendo compartilhados. Se você já é cliente Odonto Empresas, tenha acesso simplificado aos seus dados na Área do Cliente.`}
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={18}
            />
            <CollapseItem
              question="Direito de correção"
              answer={
                <>
                  <p>
                    A qualquer momento, você pode corrigir ou retificar os dados
                    pessoais que a gente controla, caso estejam desatualizados
                    ou incompletos. Esse direito garante que não serão
                    utilizados dados pessoais equivocados seus para fornecer
                    produtos e serviços da empresa ou qualquer outro tratamento
                    que se realize.
                  </p>
                </>
              }
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={19}
            />
            <CollapseItem
              question="Direito de portabilidade"
              answer={`A LGPD dá a você o direito de levar seus dados pessoais para uso em outras empresas, órgãos ou entidades. Após esse pedido, a gente envia um arquivo padrão com os dados pessoais fornecidos por você ao longo do relacionamento com a gente.`}
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={20}
            />
            <CollapseItem
              question="Direito de exclusão"
              answer={
                <>
                  <br />
                  <p>
                    Você pode pedir exclusão, bloqueio ou solicitar que seus
                    dados sejam mantidos anônimos. É importante ressaltar que,
                    nos casos a seguir, não é possível realizar essa exclusão:
                  </p>
                  <br />
                  <li>
                    se você é cliente ativo e usuário de produtos ou serviços da
                    Odonto Empresas;
                  </li>
                  <li>
                    se você tem uma ocorrência aberta em qualquer serviço de
                    atendimento ao cliente da Odonto Empresas;
                  </li>
                  <li>se há alguma dívida pendente;</li>
                  <li>
                    se a gente estiver usando seus dados para atender a normas
                    de órgãos reguladores e/ou a outras exigências legais.
                  </li>
                  <p>
                    Nesses casos, a gente presta a você todos os
                    esclarecimentos, por meio da equipe de Proteção de Dados
                    Pessoais.
                  </p>
                </>
              }
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={21}
            />
            <CollapseItem
              question="Direito de oposição"
              answer={
                <>
                  <br />
                  <p>
                    Você pode se opor à forma como a gente trata seus dados
                    pessoais, seja com base no legítimo interesse da empresa ou
                    qualquer outro, como de publicidade e de ofertas, além de
                    seus meios de envio (e-mail, telefone, SMS etc.)
                  </p>
                  <br />
                  <p>
                    Fora isso, caso você se oponha, a gente revoga também o
                    consentimento prévio fornecido e passa a não mais utilizar
                    seus dados pessoais, a menos que existam motivos legais,
                    contratuais ou regulatórios.
                  </p>
                  <br />
                  <p>
                    A qualquer momento, no Portal de Privacidade, na seção “Seus
                    direitos”, ou no Serviço de Atendimento ao Consumidor 24h
                    (0800 272 2000), você pode se opor a algum dos tratamentos
                    de dados realizados.
                  </p>
                </>
              }
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={22}
            />
          </>
        }
      />
    </S.Container>
  );
}

export default SeusDireitos;
