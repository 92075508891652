import styled from "styled-components";


export const DocumentSection = styled.div(({theme, zoom}) => ({
  backgroundColor: '#ffffff',
  padding: '40px 16px',
  
  [theme.breakpoint.lg()]: {
    padding: zoom > 130 ? '40px 16px'  : '60px 112px',
  },
}))

export const DownloadContent = styled.div(({theme, zoom}) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '40px',
  alignItems: 'center',

  a: {
    marginLeft: '8px',
    lineHeight: '24px',
    fontWeight: 500,
    fontSize: '16px',
    [theme.breakpoint.lg()]: {
      fontSize: zoom > 130 ? '16px' : '18px',
      lineHeight: zoom > 130 ? '24px' : '27px',
    }
  }
}))


export const TitleDocuments = styled.h2(({theme, zoom}) => ({
    fontsize: '28px',
    margintop: 0,
    color: '#454e4f',
    lineHeight: '42px',
  
    [theme.breakpoint.lg()]: {
        fontSize: zoom > 130 ? '28px' : '32px',
        lineHeight: zoom > 130 ? '42px' : '48px',
      }
  }))

export const DocumentsDescription = styled.p(({theme, zoom}) => ({
  fontSize: '18px',
  color: '#454e4f',
  lineHeight: '160%',
  fontWeight: 500,
  
  [theme.breakpoint.lg()]: {
    lineHeight: zoom > 130 ? '160%' : '150%',
  }
}))


export const LinksDownloadContainer = styled.div(({theme, zoom}) => ({
  a: {
    color: '#002364',
  },
  [theme.breakpoint.lg()]: {
    display: zoom > 130 ? 'block' : 'flex',
    flexDirection: 'row',
    gap: '35px',
  }  
}))

