import Text from "@ds/react/components/Text";
import styled from "styled-components";


export const Description = styled(Text)(() => ({
  fontSize: "20px",
  lineHeight: "30px",
  display: "inline-block",
  fontWeight: "500",
}));

export const SubTitle = styled(Text)(() => ({
  fontSize: "14px",
  lineHeight: "21px",
  fontWeight: "400",
}));

export const Buttom = styled.a(() => ({
  textDecoration: "none",
  display:"inline-block",
  marginTop: "32px",
  border: "1px solid #fff",
  padding: "16px 36px",
  borderRadius: "8px",
  color: "#fff",
}))