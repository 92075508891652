import DesktopFallbackImageBannerBlackFriday from "assets/images/Banner-Odonto-Sigma-Desktop-Black.png";
import FallbackImageBannerBlackFriday from "assets/images/Banner-Odonto-Sigma-Mobile-Black.png";
import DesktopImageBannerBlackFriday from "assets/images/Banner-Odonto-Sigma-Desktop-Black.webp";
import ImageBannerBlackFriday from "assets/images/Banner-Odonto-Sigma-Mobile-Black.webp";
import { useTheme } from "@ds/react";
import { getQuery } from "utils/mediaQuery";
import * as S from "./BannerBlackFriday.style";
import Banner from "components/banner";

const BannerBlackFriday = () => {
  const DescriptionBold = [
    <S.Description>
      Compre um plano Odonto Sigma até <strong>30 de novembro</strong> e ganhe{" "}
      <strong>carência zero</strong>, exceto prótese.
    </S.Description>,
  ];

  const SubTitle = [<S.SubTitle>Consulte a cobertura de próteses.</S.SubTitle>];

  const theme = useTheme();
  const bannerBlackFriday = (
    <picture>
      <source
        srcSet={DesktopImageBannerBlackFriday}
        media={getQuery(theme.breakpoint.lg())}
        type="image/webp"
      />
      <source
        srcSet={DesktopFallbackImageBannerBlackFriday}
        media={getQuery(theme.breakpoint.lg())}
        type="image/jpeg"
      />
      <source srcSet={ImageBannerBlackFriday} type="image/webp" />
      <img src={FallbackImageBannerBlackFriday} alt="banner odonto sigma" />
    </picture>
  );

  return (
    <Banner image={bannerBlackFriday} fullWidth>
      <Banner.Content
        title="Black Friday"
        description={DescriptionBold}
        subtitle={SubTitle}
      />
      <S.Buttom
        target="_blank"
        href="https://mais.correios.com.br/app/index.php"
        variant="body-01"
      >
        Encontrar uma agência
      </S.Buttom>
    </Banner>
  );
};

export default BannerBlackFriday;
