import styled from "styled-components/macro";
import { Divider, Text } from "@ds/react";

export const Header = styled.div(({ theme, zoom }) => ({
  padding: "24px 16px",
  display: "flex",
  gap: "16px",

  [theme.breakpoint.lg()]: {
    padding: zoom < 120 && "24px 112px",
  },
}));

export const Title = styled(Text)({
  fontSize: "20px",
  lineHeight: "32px",
  textTransform: "none",
});

export const FullDivider = styled(Divider)({
  width: "100%",
});
