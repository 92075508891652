import DesktopFallbackImageBanner from "assets/images/Banner-Odonto-Sigma-DesktopSet.png";
import FallbackImageBanner from "assets/images/Banner-Odonto-Sigma-MobileSet.png";
import DesktopImageBanner from "assets/images/Banner-Odonto-Sigma-DesktopSet.webp"; //webp
import ImageBanner from "assets/images/Banner-Odonto-Sigma-MobileSet.webp";
import { useTheme } from "@ds/react";
import { getQuery } from "utils/mediaQuery";
import Banner from "components/banner";

const OriginalBanner = () => {
  const theme = useTheme();
  const bannerImage = (
    <picture>
      <source
        srcSet={DesktopImageBanner}
        media={getQuery(theme.breakpoint.lg())}
        type="image/webp"
      />
      <source
        srcSet={DesktopFallbackImageBanner}
        media={getQuery(theme.breakpoint.lg())}
        type="image/jpeg"
      />
      <source srcSet={ImageBanner} type="image/webp" />
      <img src={FallbackImageBanner} alt="banner odonto sigma" />
    </picture>
  );

  return (
    <Banner image={bannerImage} fullWidth>
      <Banner.Content
        title="Plano Odonto Sigma"
        description="Esse cabe no seu bolso e tem o que você precisa: R$ 36,90/mês por pessoa."
      />
    </Banner>
  );
};

export default OriginalBanner;
