/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { useTheme } from "@ds/react";

const useBreakpoints = () => {
  const { breakpoint } = useTheme();

  const regex = /\(([^)]+)\)/; //NOSONAR: Executado no front.
  const xlMedia = window.matchMedia(breakpoint.xl().match(regex));
  const lgMedia = window.matchMedia(breakpoint.lg().match(regex));
  const mdMedia = window.matchMedia(breakpoint.md().match(regex));
  const smMedia = window.matchMedia(breakpoint.sm().match(regex));
  const xsMedia = window.matchMedia(breakpoint.xs().match(regex));

  const [xl, setXL] = useState(xlMedia.matches);
  const [lg, setLG] = useState(lgMedia.matches);
  const [md, setMD] = useState(mdMedia.matches);
  const [sm, setSM] = useState(smMedia.matches);
  const [xs, setXS] = useState(xsMedia.matches);

  const addListener = (query, listener) => {
    if ("addEventListener" in query) {
      query.addEventListener("change", listener);
    } else {
      query.addListener(listener);
    }
  };

  const removeListener = (query, listener) => {
    if ("removeEventListener" in query) {
      query.removeEventListener("change", listener);
    } else {
      query.removeListener(listener);
    }
  };

  const xlMediaChange = useCallback(() => {
    setXL(xlMedia.matches);
  }, [xlMedia]);

  const lgMediaChange = useCallback(() => {
    setLG(lgMedia.matches);
  }, [lgMedia]);

  const mdMediaChange = useCallback(() => {
    setMD(mdMedia.matches);
  }, [mdMedia]);

  const smMediaChange = useCallback(() => {
    setSM(smMedia.matches);
  }, [smMedia]);

  const xsMediaChange = useCallback(() => {
    setXS(xsMedia.matches);
  }, [xsMedia]);

  useEffect(() => {
    addListener(xlMedia, xlMediaChange);
    addListener(lgMedia, lgMediaChange);
    addListener(mdMedia, mdMediaChange);
    addListener(smMedia, smMediaChange);
    addListener(xsMedia, xsMediaChange);

    return () => {
      removeListener(xlMedia, xlMediaChange);
      removeListener(lgMedia, lgMediaChange);
      removeListener(mdMedia, mdMediaChange);
      removeListener(smMedia, smMediaChange);
      removeListener(xsMedia, xsMediaChange);
    };
  }, []);

  return { xl, lg, md, sm, xs };
};

export default useBreakpoints;
