import React from "react";
import { ReactComponent as LogosPortalPrivacidade } from "assets/svgs/LogosPortalPrivacidade.svg";
import * as S from "./HeaderPortalPrivacidade.style";

const HeaderPortalPrivacidade = () => {
  return (
    <S.Padding>
      <a href="/">
        <LogosPortalPrivacidade />
      </a>
    </S.Padding>
  );
};

export default HeaderPortalPrivacidade;
