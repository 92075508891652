import React from "react";
import { ReactComponent as Icon } from "../../assets/svgs/share.svg";
import * as S from "./CardDetail.style";

function CardDetail({ title, link }) {
  return (
    <>
      <S.Container>
        <S.PStyled>{title}</S.PStyled>
        <S.Line></S.Line>
        <S.Buttons>
          <S.ButtonStyled
            variant="tertiary-01-sm"
            onClick={() => window.open(`${link}`)}
          >
            Acessar
          </S.ButtonStyled>
          <Icon />
        </S.Buttons>
      </S.Container>
    </>
  );
}

export default CardDetail;
