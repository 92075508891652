import styled from "styled-components/macro";

export const Header = styled.header(({ theme, borderColor }) => ({
  position: "absolute",
  width: "100%",
  padding: "16px 0px",
  borderBottom: `1px solid ${
    borderColor ? borderColor : theme.color.neutral["08"]
  }`,
  zIndex: "1",
  [theme.breakpoint.lg()]: {
    width: "100%",
  },
}));
