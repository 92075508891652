import styled from "styled-components/macro";
import { Text } from "@ds/react";
import CustomDivider from "components/Footer/CustomDivider";

export const Container = styled.div(({ theme: { breakpoint }, zoom }) => ({
  display: "flex",
  flexDirection: "column",
  margin: 0,
  backgroundColor: "#002364",

  a: {
    fontSize: "24px",
    lineHeight: "36px",
  },
  ".title": {
    fontSize: "24px",
    textTransform: "none",
    lineHeight: "150%",
    margin: "0px",
    fontWeight: 700,
  },
  p: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "150%",
  },
  ".link": {
    fontSize: "16px",
  },

  ".disclaimer": {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",

    [breakpoint.lg()]: {
      fontWeight: zoom < 120 && 500,
      fontSize: zoom < 120 && "14px",
      lineHeight: zoom < 120 && "21px",
    },
  },

  "> *": {
    "> *": {
      marginBottom: "60px",
    },

    padding: "0 16px",

    [breakpoint.lg()]: {
      padding: zoom < 120 && "0 36px",
      a: {
        fontSize: zoom < 120 && "28px",
        lineHeight: zoom < 120 && "42px",
      },
      ".title": {
        fontSize: zoom < 120 && "28px",
        lineHeight: zoom < 120 && "42px",
      },
      p: {
        fontSize: zoom < 120 && "16px",
      },
    },

    [breakpoint.xl()]: {
      padding: zoom < 120 && "0 112px",
      margin: zoom < 120 && "auto",
    },
  },
}));

export const Message = styled(Text)(() => ({
  marginBottom: "40px",
  textTransform: "none",
}));

export const Section = styled.div(
  ({ theme: { spacing, breakpoint }, zoom }) => ({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100%",
    minHeight: "min-content",

    [breakpoint.lg()]: {
      flexDirection: zoom < 120 && "row",

      "> *": {
        marginRight: zoom < 120 && spacing.inline.md,

        ":last-child": {
          marginRight: zoom < 120 && 0,
        },
      },
    },
  })
);

export const Item = styled.div(({ theme: { spacing, breakpoint }, zoom }) => ({
  display: "inline-flex",
  flex: 1,
  width: "100%",
  boxSizing: "content-box",
  marginBottom: "60px",
  lineHeight: spacing.inline["xs"],

  ":last-child": {
    marginBottom: 0,
  },

  [breakpoint.lg()]: {
    marginBottom: zoom < 120 && 0,
  },
}));

export const Divider = styled(CustomDivider)(({ theme: { color } }) => ({
  width: "2px",
  height: "auto",
  backgroundColor: "#008C84",
}));

export const Content = styled.div(() => ({
  width: "100%",
  marginLeft: "16px",

  "> *": {
    marginBottom: "12px",

    ":last-child": {
      marginBottom: 0,
    },
  },
}));

export const Block = styled.div(() => ({
  "> *": {
    marginBottom: "4px",

    "&.normal": {
      fontWeight: 500,
    },

    ":last-child": {
      marginBottom: 0,
    },
  },
  h6: {
    display: "block",
  },
}));

export const PhoneNumber = styled.a(({ theme }) => ({
  color: theme.color.line.light,
}));
