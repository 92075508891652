import CustomTable from "components/CustomTable";
import React, { useState } from "react";
import CollapseItem from "../CollapseItem/CollapseItem";
import ResponsiveCard from "../ResponsiveCard";
import * as S from "./PoliticaCookies.style";

function PoliticaCookies() {
  const [currentItem, setCurrentItem] = useState(-1);

  return (
    <S.Container id="Políticadecookies">
      <ResponsiveCard
        title="Política de Cookies"
        maxContent
        customSection={
          <>
            {/* <StatusTitle title="E-mail enviado com sucesso." /> */}
            <S.TextStyled variant="body-02-sm">
              Os cookies são essenciais para trazer um conteúdo personalizado
              para você, uma melhor apresentação dos nossos produtos e garantir
              um bom funcionamento do nosso site.
            </S.TextStyled>

            <CollapseItem
              question="O que são Cookies?"
              answer={
                <>
                  <p>
                    Cookies são pedaços de informações armazenadas diretamente
                    em seu dispositivo quando você entra em endereços na
                    internet.
                  </p>
                  <p>
                    Ao acessar algum dos sites ou aplicativos da Odonto
                    Empresas, por exemplo, a gente pede a permissão do uso
                    desses cookies, dando a você a possibilidade de escolher
                    qual grupo de cookies podem ser utilizados.
                  </p>
                  <p>
                    Os cookies ajudam a entender melhor seu comportamento e suas
                    preferências para que sejam disponibilizados produtos de
                    acordo com seu perfil. São empregados também para remunerar
                    parceiros comerciais, quando você acessa ofertas da Odonto
                    Empresas por meio de outros sites ou plataformas.
                  </p>
                </>
              }
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={1}
            />
            <CollapseItem
              question="Quais cookies utilizamos e como utilizamos?"
              answer={
                <>
                  <h2>Cookies essenciais</h2>
                  <p>
                    Servem para você conseguir navegar sem complicações. Já são
                    inclusos e ativos a partir do primeiro momento em que você
                    entra no site da Odonto Empresas. Além destes, você tem o
                    poder de escolha sobre quais outros tipos de cookies a gente
                    pode utilizar.
                  </p>
                  <h2>Cookies analíticos</h2>
                  <p>
                    Têm a função de entender melhor o seu comportamento e
                    analisar os dados sobre as ações tomadas por você no site,
                    com o objetivo de verificar a performance dos produtos da
                    Odonto Empresas.
                  </p>
                  <h2>Cookies de marketing</h2>
                  <p>
                    São usados para mapear sua jornada de compra, possibilitando
                    o uso desses dados para oferecer a você produtos de forma
                    personalizada. Servem também para entender que você teve
                    interesse por um dos produtos da companhia durante a sua
                    navegação no site ou na de sites parceiros, para identificar
                    suas preferências e direcionar as melhores ofertas e para
                    remunerar parceiros comerciais que apresentam anúncios
                    Odonto Empresas em seus sites e plataformas (no momento em
                    que você interage com eles).
                  </p>
                </>
              }
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={2}
            />
            <CollapseItem
              question="Cookies essenciais"
              answer={
                <>
                  <p>
                    Esses cookies são essenciais para o funcionamento dos sites
                    da Odonto Empresas e não é possível desativá-los. São
                    divididos em cookies de autenticação, que auxiliam os
                    sistemas a saber que você é você, e cookies técnicos,
                    necessários para que as funcionalidades do site estejam
                    disponíveis. Ao clicar nas opções abaixo, você pode ver os
                    detalhes sobre esses cookies.
                  </p>

                  <h2>Cookies de autenticação</h2>

                  <CustomTable
                    headers={[
                      "Plataforma",
                      "O que ele faz",
                      "Quais dados são coletados",
                      "Como usamos esse cookie",
                    ]}
                    rows={[
                      [
                        "Oauth",
                        "Quando é necessário acessar os serviços de informações referentes a cotações de produtos, administração de produtos contratados e outros serviços internos, é gerado um token de autorização e autenticação para a realização das chamadas aos nossos servidores.",
                        "Esse processo não coleta nenhuma informação do dispositivo, ele apenas faz uma tratativa entre os nossos sistemas para manter a segurança no tráfego de informações.",
                        <>
                          Os cookies são armazenados na sessão do navegador e
                          identificados com a inclusão do termo oauth no seu
                          nome, expirando quando ele é fechado.
                          <br />
                          <br />
                          São também utilizados nas chamadas realizadas para
                          comunicação de informações entre os sistemas de nossas
                          empresas.
                        </>,
                      ],
                    ]}
                  />
                  <p>
                    Por questões técnicas, os nomes desses cookies estão
                    sujeitos a alterações (em especial o Google Tag Manager).
                  </p>

                  <h2>Cookies Técnicos</h2>

                  <CustomTable
                    headers={[
                      "Plataforma",
                      "O que ele faz",
                      "Quais dados são coletados",
                      "Como usamos esse cookie",
                    ]}
                    rows={[
                      [
                        "Identificação do dispositivo",
                        "É um serviço interno nosso que identifica quando você acessa os nossos serviços e sites pela primeira vez, gerando um identificador único desse dispositivo de acesso.",
                        "Esse processo capta diversas informações do dispositivo, com o objetivo de criar o identificador único. São tratados apenas dados anônimos do dispositivo, como o tipo do navegador e plugins instalados, driver de áudio instalado, capacidade de memória e fabricante do dispositivo, versões de sistema e navegador.",
                        <>
                          O cookie gerado com a identificação do dispositivo é
                          armazenado de forma permanente no localStorage do
                          navegador, com o nome cnpData, e tem duração por prazo
                          indefinido.
                          <br />
                          <br />
                          Ele é utilizado para a centralização do aceite da
                          Política de Cookies do site e como identificação de
                          todos os dados captados após a sua permissão.
                        </>,
                      ],
                      [
                        "Google Tag Manager",
                        <>
                          É uma plataforma de gestão de tags que permite a
                          implementação e a atualização de tags e snippets de
                          código no website de forma rápida, através de uma só
                          interface. Essa plataforma não acessa dados pessoais,
                          apenas controla a inclusão de outras tags que podem
                          administrar seus dados e do dispositivo, tratadas em
                          tópicos dedicados nesta Política. Mais informações na{" "}
                          <a
                            href="https://marketingplatform.google.com/intl/pt-BR_br/about/tag-manager/"
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            página do Google Tag Manager.
                          </a>
                        </>,
                        "Essa plataforma não capta nenhum cookie e nenhum dado seu, não tendo, para o seu funcionamento, nenhuma informação pessoal sua captada.",
                        <>
                          Os cookies gerados pela plataforma são gravados na
                          sessão do navegador e são identificados com a inclusão
                          do termo gtm no seu nome, sendo excluídos quando o
                          navegador é fechado.
                          <br />
                          <br />
                          Eles são utilizados exclusivamente para vincular
                          informações do dispositivo com os dados do Google Tag
                          Manager, sem caráter analítico das informações, apenas
                          para funcionamento técnico da plataforma.
                        </>,
                      ],
                      [
                        "Geolocation",
                        "É um serviço interno nosso que identifica quando você acessa os nossos serviços e sites, momento em que analisamos o endereço IP da origem do acesso para localizar o país de acesso ao site.",
                        "Esse método de busca dos dados de geolocalização não é preciso ou invasivo, trabalha baseado em dados públicos sobre os endereços de IP fornecidos pelos provedores de acesso à internet.",
                        <>
                          O cookie gerado com a localização do dispositivo é
                          armazenado de forma permanente no localStorage do
                          navegador, com o nome cnpData, e tem duração por prazo
                          indefinido.
                          <br />
                          <br />
                          Ele é utilizado como parte do processo de
                          identificação única do dispositivo, necessário para o
                          processo de apuração das definições quanto à
                          autorização para o uso de cookies por você no
                          dispositivo.
                        </>,
                      ],
                    ]}
                  />
                </>
              }
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={3}
            />
            <CollapseItem
              question="Cookies analíticos"
              answer={
                <>
                  <p>
                    De uso exclusivamente interno, os cookies analíticos ajudam
                    a gente a perceber como melhorar os serviços prestados a
                    você. Eles se limitam a avaliar a sua interação com o site
                    enquanto navega, procurando entender como melhorar sua
                    experiência com a gente na hora de falar com você.
                  </p>

                  <CustomTable
                    headers={[
                      "Plataforma",
                      "O que ele faz",
                      "Quais dados são coletados",
                      "Como usamos esse cookie",
                    ]}
                    rows={[
                      [
                        "Google Analytics",
                        <>
                          Interpreta, de forma anônima, a utilização e a
                          interação dos usuários com o nosso site. Mais
                          informações na{" "}
                          <a
                            href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            página do Google Analytics.
                          </a>
                        </>,
                        "Essa plataforma capta dados específicos da sua navegação no nosso site, como hora de entrada, hora de saída, páginas visitadas, cliques e ações efetuadas, dados da origem de seu acesso e cruzamento de campanhas de mídia.",
                        <>
                          Os cookies gerados pela plataforma são gravados na
                          sessão do navegador e são identificados com a inclusão
                          do termo ga no seu nome. Parte dos cookies que são
                          destinados à identificação do dispositivo é mantida
                          por um prazo de dois anos e outra parte, de
                          identificação da sessão, é mantida por 30 minutos.
                          <br />
                          <br />
                          Eles são utilizados como identificação de todos os
                          dados captados na navegação do dispositivo, visando à
                          sua vinculação na base de dados do Google Analytics.
                        </>,
                      ],
                      [
                        "Hotjar",
                        <>
                          É uma plataforma que nos ajuda a entender melhor a sua
                          experiência no uso dos nossos serviços, permitindo
                          analisar as funcionalidades e as informações na{" "}
                          <a
                            href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies"
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            página da Hotjar.
                          </a>
                        </>,
                        "Essa plataforma capta dados específicos da sua navegação no nosso site, como páginas visitadas, cliques e ações efetuadas, além de trechos de conteúdos visualizados.",
                        <>
                          Com duração de um ano, os cookies gerados pela
                          plataforma são armazenados de forma permanente no
                          localStorage do navegador com as chaves que contêm no
                          nome o valor hj. Outros cookies armazenados em sua
                          sessão também são utilizados, sendo identificados por
                          conter o termo hj no nome. São excluídos após o
                          fechamento do navegador.
                          <br />
                          <br />
                          Esses cookies são utilizados como identificação de
                          todos os dados captados na navegação do dispositivo,
                          visando à sua vinculação na base de dados do HotJar.
                        </>,
                      ],
                    ]}
                  />
                </>
              }
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={4}
            />
            <CollapseItem
              question="Cookies de marketing"
              answer={
                <>
                  <p>
                    Esses cookies utilizam informações sobre a sua navegação
                    para trazer a publicidade mais relevante a você,
                    personalizada para suas necessidades.
                  </p>

                  <p>
                    Também são empregados para limitar a sua visualização de
                    anúncios e ajudar a avaliar a eficácia das campanhas de
                    marketing da Odonto Empresas. Eles são colocados por redes
                    publicitárias de terceiros em nome e com permissão da
                    companhia.
                  </p>

                  <p>
                    Eles identificam também que você visitou sites da empresa e
                    de qual endereço você veio. Essa informação pode ser
                    compartilhada com fornecedores de mídia para que anúncios
                    sejam apresentados a você em outras páginas, caso a gente
                    entenda que você tem interesse, e para remunerar parceiros
                    que apresentam publicidade Odonto Empresas.
                  </p>

                  <p>
                    Eventualmente, algum novo cookie pode estar ativo em sites
                    da companhia e ainda não terem sido atualizados nesta
                    política. Todos os cookies de marketing, porém, dependem de
                    seu consentimento, cujas opções você sempre pode rever.
                  </p>

                  <CustomTable
                    headers={[
                      "Plataforma",
                      "O que ele faz",
                      "Quais dados são coletados",
                      "Como usamos esse cookie",
                    ]}
                    rows={[
                      [
                        "Facebook",
                        <>
                          É a plataforma de publicidade do Facebook que trabalha
                          na observação dos dados de vínculo entre as campanhas
                          veiculadas pela Odonto Empresas na rede social.
                          <br />
                          <br />O objetivo é identificar problemas de
                          configuração, criar grupos de audiência baseados em
                          comportamentos no site, além de medir performance de
                          campanhas. Mais informações podem ser obtidas na{" "}
                          <a
                            href="https://www.facebook.com/policies_center/"
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            página do Facebook.
                          </a>
                        </>,
                        "A plataforma de publicidade do Facebook mapeia as páginas visualizadas por você e anexa esses dados a um identificador da própria plataforma, que faz o vínculo de forma anônima com o seu perfil no Facebook.",
                        <>
                          Os cookies gerados pela plataforma são gravados na
                          sessão do navegador e são identificados com a inclusão
                          dos termos act, c_user, datr, dpr, fr, presence, sb,
                          spin, wd ou xs no seu nome, sendo mantidas no seu
                          navegador por um ano.
                          <br />
                          <br />
                          Os cookies são utilizados pelo Facebook para vincular
                          as informações da sua navegação e, assim, possibilitar
                          a criação de audiências no Facebook, visando à
                          configuração de campanhas de marketing direcionadas
                          para o público, medição de performance das campanhas
                          realizadas e remarketing aplicado a dispositivos que
                          realizaram conversões nas campanhas de marketing
                          realizadas.
                        </>,
                      ],
                      [
                        "Google Marketing Platform",
                        <>
                          É a plataforma de publicidade do Google, composta
                          pelas ferramentas Google Campaign Manager, Google
                          Display & Video e Google Ads, que trabalham na
                          observação dos dados de vínculo entre as campanhas
                          veiculadas pela Odonto Empresas no Google para
                          identificar problemas de configuração, criar grupos de
                          audiência baseados em comportamentos no site, além de
                          medir performance de campanhas. Mais informações podem
                          ser obtidas na{" "}
                          <a
                            href="https://policies.google.com/technologies/ads"
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            página do Google.
                          </a>
                        </>,
                        "A plataforma de marketing do Google expande também o uso do Google Analytics para mapear audiências para as campanhas baseadas nos dados de navegação dos dispositivos em nosso site.",
                        <>
                          Os cookies gerados pela plataforma são gravados na
                          sessão do navegador e são identificados com a inclusão
                          dos termos gads, dsid, ide, test_cookie ou aid no seu
                          nome, sendo mantidos no seu navegador por um ano.
                          <br />
                          <br />
                          Os cookies são utilizados pelo Google para vincular as
                          informações da sua navegação e, assim, possibilitar a
                          criação de audiências no Google Display & Video
                          (antigo DoubleClick Bid Manager) e Google Ads, visando
                          à configuração de campanhas de marketing direcionadas
                          para o público, medição de performance das campanhas
                          realizadas e remarketing aplicado a dispositivos que
                          realizaram conversões nas campanhas de marketing
                          realizadas.
                        </>,
                      ],
                      [
                        "Interação com campanhas",
                        "É um serviço interno da Odonto Empresas que faz o mapeamento da navegação do dispositivo em conjunto com as nossas campanhas de marketing, visando a ter informações sobre as ações realizadas por você. O objetivo é criar um modelo de atribuição das conversões realizadas, apresentando a participação das campanhas no processo de aquisição de novos clientes.",
                        "Esse serviço capta dados específicos dos impactos e das suas interações com as campanhas de marketing da Odonto Empresas, obtendo os dados de qual campanha você visualizou, em qual clicou e mapeando em conjunto os dados das ações realizadas em nosso site dentro do contexto da campanha.",
                        <>
                          Esse serviço usa o cookie gerado com a identificação
                          do dispositivo, é armazenado de forma permanente no
                          localStorage do navegador, com o nome cnpData, e tem
                          duração por prazo indefinido.
                          <br />
                          <br />O cookie é utilizado para vincular os dados
                          captados dos impactos e das interações com as
                          campanhas no dispositivo e, assim, armazenar na base
                          de dados da Odonto Empresas.
                        </>,
                      ],
                    ]}
                  />
                </>
              }
              selectedItem={currentItem}
              onChange={(item) => setCurrentItem(item)}
              item={5}
            />
          </>
        }
      />
    </S.Container>
  );
}

export default PoliticaCookies;
