import React, { useEffect, useRef } from "react";
import { isInViewport } from "utils/viewport";

import "./CollapseItemStyle.scss";
import Union from "../../assets/svgs/Union-Blue.svg";

const CollapseItem = ({ question, answer, selectedItem, item, onChange }) => {
  const questionRef = useRef();
  const lastOpenRef = useRef();

  const isOpen = selectedItem === item;

  const scroll = () => {
    setTimeout(() => questionRef.current.scrollIntoView(), 100);
  };
  const toggle = () => {
    lastOpenRef.current = !isOpen;
    if (isOpen) {
      onChange(-1);
      if (!isInViewport(questionRef.current)) {
        scroll();
      }
    } else {
      onChange(item);
    }
  };

  const onKeyUp = (e, zoom) => {
    if (e.key === "Enter") toggle();
  };

  useEffect(() => {
    if (!isOpen && lastOpenRef.current && !isInViewport(questionRef.current)) {
      scroll();
    }
    lastOpenRef.current = isOpen;
  }, [isOpen]);

  return (
    <div className="Section_Collapse">
      <div
        tabIndex={0}
        className="item make-click"
        data-label={question}
        onKeyUp={(e) => onKeyUp(e)}
        onClick={toggle}
      >
        <div className="Question">
          <h4 ref={questionRef} className="collapsible">
            {question}
          </h4>
          <img
            src={Union}
            alt="Union"
            className={`icon ${isOpen && "rotate180"}`}
          />
        </div>
        <div
          style={{
            maxHeight: isOpen
              ? document.getElementById(`container${item}`)?.clientHeight + 20
              : 0,
          }}
          className={`content${isOpen ? " height-auto" : ""}`}
        >
          <div id={`container${item}`}>{answer}</div>
        </div>
      </div>
    </div>
  );
};

export default CollapseItem;
