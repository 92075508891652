import * as S from "./BannerContent.style.js";

const BannerContent = ({
  title,
  description,
  subtitle,
  titleColor,
  descriptionColor,
  Buttom,
}) => {
  return (
    <S.BannerContent descriptionColor={descriptionColor}>
      <S.BannerText variant="headline-01" titleColor={titleColor}>
        {title}
      </S.BannerText>
      <p className="description">{description}</p>
      <p className="subtitle">{subtitle}</p>
    </S.BannerContent>
  );
};

export default BannerContent;
