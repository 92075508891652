import React, { useContext } from "react";
import * as S from "./ItemContent.style";
import { phoneToHref } from "utils/string";
import { Text } from "@ds/react";
import OdontoContext from "context/OdontoContext";

const ItemContent = () => {
  const { percentageZoom } = useContext(OdontoContext);
  return (
    <S.Container zoom={percentageZoom}>
      <div>
        <S.Message variant="body-02-md"></S.Message>
        <S.Section zoom={percentageZoom}>
          <S.Item>
            <S.Divider orientation="vertical" />
            <S.Content>
              <S.Block>
                <h3 className="title">Fale com a gente</h3>
              </S.Block>
              <S.Block>
                <S.PhoneNumber
                  variant="primary-lg-01"
                  role="button"
                  href={phoneToHref("0800 272 2021")}
                >
                  0800 272 2021
                </S.PhoneNumber>
                <br />
                <Text variant="caption-02" className="disclaimer">
                  <strong>Consultas ou serviços</strong>, de segunda a sexta,
                  das 8h às 21h, exceto em feriados nacionais.
                </Text>
              </S.Block>
            </S.Content>
          </S.Item>
          <S.Item zoom={percentageZoom}>
            <S.Divider orientation="vertical" />
            <S.Content>
              <S.Block>
                <h3 className="title">SAC 24h</h3>
              </S.Block>
              <S.Block>
                <S.PhoneNumber
                  variant="primary-lg-01"
                  role="button"
                  href={phoneToHref("0800 272 2000")}
                >
                  0800 272 2000
                </S.PhoneNumber>
                <br />
                <Text variant="caption-02" className="disclaimer">
                  Informações ou dúvidas dos produtos, reclamação, contestação,
                  suspensão ou cancelamentos.
                </Text>
              </S.Block>
            </S.Content>
          </S.Item>

          <S.Item zoom={percentageZoom}>
            <S.Divider orientation="vertical" />
            <S.Content>
              <S.Block>
                <h3 className="title">Ouvidoria</h3>
              </S.Block>
              <S.Block>
                <S.PhoneNumber
                  variant="primary-lg-01"
                  role="button"
                  href={phoneToHref("0800 772 2169")}
                >
                  0800 272 2022
                </S.PhoneNumber>
                <Text variant="caption-02" className="disclaimer">
                  Reclamações não atendidas satisfatoriamente por outros canais,
                  sugestões ou elogios, de segunda a sexta, das 8h às 18h,
                  exceto em feriados nacionais.
                </Text>
              </S.Block>
            </S.Content>
          </S.Item>
        </S.Section>
      </div>
    </S.Container>
  );
};

export default ItemContent;
