import styled from "styled-components/macro";

export const StyledBurgerButton = styled.button(({ theme, open, zoom }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  height: "1.3rem",
  background: "transparent",
  border: "none",
  cursor: "pointer",
  padding: "0",
  zIndex: open ? "10" : "0",
  transition: "all 0.3s linear",
  marginRight: "20px",
  color: "#ffffff",

  "&:focus": {
    outline: "none",
  },

  div: {
    width: "2rem",
    height: "2.38px",
    background: "#ffffff",
    borderRadius: "10px",
    transition: "all 0.3s linear",
    position: "relative",
    transformOrigin: "1px",

    ":first-child": {
      transform: open ? "rotate(45deg)" : "rotate(0)",
      position: open ? "relative" : "unset",
      bottom: open ? "28%" : 0,
    },

    ":nth-child(2)": {
      opacity: open ? "0" : "1",
      transform: open ? "translateX(20px)" : "translateX(0)",
    },

    ":nth-child(3)": {
      transform: open ? "rotate(-45deg)" : "rotate(0)",
      position: open ? "relative" : "unset",
      top: open ? "4%" : 0,
    },
  },

  [theme.breakpoint.xl()]: {
    display: zoom < "120" ? "none" : "flex",
  },
}));
