import AtualizacoesPolitica from "components/AtualizacoesPolitica/AtualizacoesPolitica.js";
import ExercaSeusDireitos from "components/ExercaSeusDireitos/ExercaSeusDireitos.js";
import FooterPortalPrivacidade from "components/FooterPortalPrivacidade/FooterPortalPrivacidade.js";
import Header from "components/Header";
import PersonalizarCookies from "components/PersonalizarCookies/PersonalizarCookies.js";
import PoliticaCookies from "components/PoliticaCookies/PoliticaCookies.js";
import PoliticaPrivacidade from "components/PoliticaPrivacidade/PoliticaPrivacidade.js";
import PortalPrivacidadeBanner from "components/PortalPrivacidadeBanner/PortalPrivacidadeBanner.js";
import SeusDireitos from "components/SeusDireitos/SeusDireitos.js";

function PortalPrivacidade() {
  return (
    <>
      <Header PortalPrivacidade />
      <main>
        <article>
          <PortalPrivacidadeBanner />
          <PoliticaPrivacidade />
          <PoliticaCookies />
          <PersonalizarCookies />
          <SeusDireitos />
          <ExercaSeusDireitos />
          <AtualizacoesPolitica />
        </article>
      </main>
      <FooterPortalPrivacidade />
    </>
  );
}

export default PortalPrivacidade;
