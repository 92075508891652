import styled from "styled-components/macro";
import { Text } from "@ds/react";

export const MenuItem = styled.a(({ theme, isSelected }) => ({
  padding: "25px 0",
  borderBottom: "1px solid #E5E5E5",
  cursor: "pointer",
  textDecoration: "none",

  span: {
    color: isSelected ? theme.color.brandPrimary.light : theme.color.neutral["02"],
    fontFamily: "Raleway, sans-serif",
  },

  div: {
    display: "flex",
    alignItems: "center",
  },

  [theme.breakpoint.xl()]: {
    border: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    span: {
      color: "#ffff",

      fontFamily: "Raleway, sans-serif",
      fontSize: "14px",
    },
  },
}));

export const MenuItemIcon = styled(Text)(({ theme }) => ({
  marginBottom: "2px",
  marginRight: "10px",
}));

export const Divider = styled.hr(({ theme, isSelected, width = 134 }) => ({
  display: "none",

  [theme.breakpoint.xl()]: {
    display: "block",
    border: "none",
    height: "4%",
    width: width,
    position: "absolute",
    top: 85,
    background: isSelected ? "#ffffff" : "transparent",
  },
}));
