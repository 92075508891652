import Content from "components/Content";
import BannerContent from "./BannerContent";
import * as S from "./Banner.style";
import { useContext } from "react";
import OdontoContext from "context/OdontoContext";

const Banner = ({ children, image }) => {
  const { percentageZoom } = useContext(OdontoContext);
  return (
    <section role="banner">
      <S.Banner>
        <Content noPadding fullWidth>
          <S.ImageContainer zoom={percentageZoom}>{image}</S.ImageContainer>
          <Content style={{ margin: "105px 0 0 0" }}>
            <S.BannerContent zoom={percentageZoom}>{children}</S.BannerContent>
          </Content>
        </Content>
      </S.Banner>
    </section>
  );
};

Banner.Content = BannerContent;

export default Banner;
