import React, { useContext } from "react";

import LogoLocation from "./../../assets/svgs/Illust-Pin-Location-Inverse-01.svg";

import * as S from "./Correios.style"
import OdontoContext from "context/OdontoContext";

const Correios = () => {
  const { percentageZoom } = useContext(OdontoContext);

  return (
    <section>
      <S.CorreiosSection className="make-section" data-section="correios section">
        <S.CorreiosContent zoom={percentageZoom}>
          <div className="location__logo" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {" "}
            <img src={LogoLocation} alt="Logo localização" />
          </div>

          <div>
            <S.ParagraphProcureAgencia>
              Contrate seu plano odontológico na agência dos Correios mais próxima.
            </S.ParagraphProcureAgencia>
            <a
              className="make-click"
              data-label={"Encontrar uma agência botão"}
              href="https://mais.correios.com.br/app/index.php"
              target="_blank"
              rel="noreferrer"
            >
              <S.CorreiosButton>Encontrar uma agência</S.CorreiosButton>
            </a>
          </div>
        </S.CorreiosContent>
      </S.CorreiosSection>
    </section>
  );
};

export default Correios;
