import React from "react";
import { Text } from "@ds/react";
import * as S from "./MenuItem.style";

const MenuItem = ({ icon, label, path, onClick, isSelected, setOpen }) => {
  return (
    <S.MenuItem className="make-click" href={path} onClick={setOpen} isSelected={isSelected}>
      <div>
        {icon && <S.MenuItemIcon>{icon}</S.MenuItemIcon>}
        <Text variant="body-02-md">{label}</Text>
      </div>

      <S.Divider width={label.length * 10} />
    </S.MenuItem>
  );
};

export default MenuItem;
