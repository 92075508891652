import styled from "styled-components/macro";

export const MenuList = styled.div(({ theme }) => ({
  padding: "0 16px",
  display: "flex",
  flexDirection: "column",
  button: {
    width: " 20px ",
    marginTop: "25px",
  },
  [theme.breakpoint.xl()]: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 32px",
    gap: "0px",
    padding: "16px 8px",
    a: {
      padding: "8px",
    },
  },
}));
