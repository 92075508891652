import styled from "styled-components/macro";
import { Text } from "@ds/react";

export const BannerContent = styled.div(
  ({ theme, color, descriptionColor }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    // paddingTop: "420px",
    width: "100%",
    ".description": {
      color: descriptionColor ? descriptionColor : "white",
      textTransform: "none",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "150%",
      margin: "0px",

      [theme.breakpoint.lg()]: {
        fontSize: "24px",
      },
    },
    ".subtitle": {
      color: "white",
      textTransform: "none",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "160%",
      margin: "0px",
      [theme.breakpoint.lg()]: {
        fontSize: "20px",
      },
    },

    [theme.breakpoint.lg()]: {
      maxWidth: "600px",
      paddingBottom: 0,
      paddingTop: "60px",
    },
  })
);

export const BannerText = styled(Text)(
  ({ theme: { color, breakpoint }, titleColor }) => ({
    color: titleColor ? titleColor : color.neutral["08"],
    textTransform: "none",
    fontSize: "32px",
    lineHeight: "150%",

    [breakpoint.lg()]: {
      fontSize: "40px",
    },
  })
);

export const Subtitle = styled(Text)(({ theme: { color, breakpoint } }) => ({
  color: color.neutral["08"],
  textTransform: "none",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "160%",

  [breakpoint.lg()]: {
    fontSize: "20px",
  },
}));
