import Main from "../components/main/index.js";
import HomeInformation from "../components/homeInformation/homeInformation";
import Specialty from "../components/specialty/specialty";
import Correios from "../components/Correios/Correios";
import TiraDuvidas from "../components/TiraDuvidas/TiraDuvidas";
import RedeCredenciada from "components/RedeCredenciada/RedeCredenciada.js";
import DonwloadsDocumentos from "components/DonwloadsDocumentos/DonwloadsDocumentos.js";
import Footer from "components/Footer/index.js";
import Advice from "components/Advice/Advice.js";
import Header from "../components/Header/index.js";

function Home() {
  return (
    <>
      <Header />
      <main>
        <article>
          <Main />
          <Advice />
          <HomeInformation />
          <Specialty />
          <RedeCredenciada />
          <Correios />
          <DonwloadsDocumentos />
          <TiraDuvidas />
        </article>
      </main>
      <Footer />
    </>
  );
}

export default Home;
